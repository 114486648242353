import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Tooltip,
  OverlayTrigger,
  Dropdown,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import API from "../../api/API";
import { toast } from "react-toastify";
import AdminNavbar from "./AdminNavbar";
import { MdOutlineSort } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import loader from "../../assests/loading1.gif";

const AdminDashboard = () => {
  const [adminData, setAdminData] = useState({});
  const [productData, setProductData] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const token = location.state?.token || localStorage.getItem("token");
  const userID = adminData._id;

  const getAdminData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getadmin`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API}/product/showproducts`);
      setProductData(response.data.products);
      setFilteredProductData(response.data.products);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    if (category === "") {
      setFilteredProductData(productData);
    } else {
      const filteredProducts = productData.filter(
        (product) => product.productCategory === category
      );
      setFilteredProductData(filteredProducts);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterProducts(selectedCategory, event.target.value);
  };

  const filterProducts = (category, search) => {
    let filteredProducts = productData;

    if (category) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productCategory === category
      );
    }

    if (search) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.productTitle.toLowerCase().includes(search.toLowerCase()) ||
          product.productContent.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredProductData(filteredProducts);
  };

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      getAdminData();
      getProductData();
    }
  }, [token]);

  const navigateToUserQRPage = () => {
    navigate(`/userQRCode/${id}`);
  };
  const navigateToUserOfferadsPage = () => {
    navigate(`/offerview/${id}`);
  };
  const navigateToEditPostPage = (productID, _id) => {
    navigate(`/editproduct/${_id}-${productID}`);
  };
  const handleDeleteProduct = async (_id, product, getProductData) => {
    const shouldDelete = window.confirm(
      `Are you sure you want to delete this ${product.productTitle} post?`
    );

    if (shouldDelete) {
      try {
        const response = await axios.delete(
          `${API}/product/deleteproduct/${_id}`
        );
        toast.success(response.data.message);
        getProductData();
      } catch (error) {
        toast.error(error.response.data.message);
        // console.error("Error deleting offer:", error);
      }
    }
  };

  return (
    <>
      <AdminNavbar adminData={adminData} />
      <div className="container mt-5 pt-5">
        <h2>Welcome {adminData.userName}</h2>
        <h1>Product Dashboard</h1>
      </div>

      <div
        className="container d-flex justify-content-between mb-3"
        style={{ gap: "1.2rem" }}
      >
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            style={{
              margin: "0",
              padding: "7px 15px",
              textTransform: "lowercase",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "30px",
              backgroundColor: "#2e6ca4",
              border: "none",
              color: "#fff",
            }}
          >
            <MdOutlineSort style={{ fontSize: "20px" }} />
            Sort
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCategoryChange("")}>
              All
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("fish")}>
              Fish
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("birds")}>
              Birds
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("cat")}>
              Cat
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("dog")}>
              Dog
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("hamster")}>
              Hamster
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("pigeon")}>
              Pigeon
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("rabbit")}>
              Rabbit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCategoryChange("poultry")}>
              Poultry
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <InputGroup className="search-bar">
          <FormControl
            placeholder="Search products"
            aria-label="Search products"
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ borderRadius: "30px", padding: "10px 20px", border: "1px solid #ced4da" }}
          />
        </InputGroup>
      </div>

      <div
        className="table-responsive"
        style={{
          overflow: "scroll",
          margin: "1.2rem",
        }}
      >
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            {/* <img src={loader} alt="loading"/> */}
          </div>
        ) : (
          <table className="table table-hover table-fluid product-table">
            <thead
              style={{
                position: "sticky",
                top: "0",
                zIndex: "1",
                background: "#fff",
              }}
            >
              <tr>
                <th className="cell-padding">#</th>
                <th className="cell-padding">ProductID</th>
                <th className="cell-padding">Product Title</th>
                <th className="cell-padding">Product Content</th>
                <th className="cell-padding">MRP Price</th>
                <th className="cell-padding">New Price</th>
                <th className="cell-padding">Quantity</th>
                <th className="cell-padding">Category</th>
                <th className="cell-padding">Sub Category</th>
                <th className="cell-padding">Product Image</th>
                <th className="cell-padding">User</th>
                <th className="cell-padding">Edit</th>
                <th className="cell-padding">Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredProductData && Array.isArray(filteredProductData) && filteredProductData.length > 0 ? (
                filteredProductData.map((product, index) => (
                  <tr key={product._id}>
                    <td className="cell-padding">{index + 1}</td>
                    <td className="cell-padding">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-id-${product._id}`}>
                            {product.productID}
                          </Tooltip>
                        }
                      >
                        <span>
                          {product.productID.length > 8
                            ? product.productID.slice(0, 8) + "..."
                            : product.productID}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td className="cell-padding">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-title-${product._id}`}>
                            {product.productTitle}
                          </Tooltip>
                        }
                      >
                        <span>
                          {product.productTitle.length > 25
                            ? product.productTitle.slice(0, 25) + "..."
                            : product.productTitle}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td
                      className="cell-padding"
                      style={{
                        maxWidth: "25px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${product._id}`}>
                            {product.productContent}
                          </Tooltip>
                        }
                      >
                        <span>
                          {product.productContent.length > 25
                            ? product.productContent.slice(0, 25) + "..."
                            : product.productContent}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td className="cell-padding" style={{ textDecoration: "line-through" }}>
                      &#8377;{product.mrpPrice}
                    </td>
                    <td className="cell-padding">&#8377;{product.newPrice}</td>
                    <td className="cell-padding">{product.quantity}</td>
                    <td className="cell-padding">{product.productCategory}</td>
                    <td className="cell-padding">{product.productSubCategory}</td>
                    <td className="cell-padding">
                      <img
                        src={product.productImage}
                        className="product-image"
                        style={{
                          width: "35px",
                          height: "35px",
                          objectFit: "cover",
                        }}
                      />
                    </td>
                    <td className="cell-padding">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${product._id}`}>
                            {product.userName}
                          </Tooltip>
                        }
                      >
                        <span>
                          {product.productContent.length > 8
                            ? product.userName.slice(0, 8) + "..."
                            : product.userName}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td className="cell-padding" style={{ textAlign: "center" }}>
                      <BiSolidEdit
                        style={{
                          fontSize: "24px",
                          color: "#3C5B6F",
                          marginTop: "7px",
                        }}
                        onClick={() =>
                          navigateToEditPostPage(product.productID, product._id)
                        }
                      />
                    </td>
                    <td className="cell-padding" style={{ textAlign: "center" }}>
                      <MdDeleteForever
                        style={{
                          fontSize: "24px",
                          color: "#d11a2a",
                          marginTop: "7px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDeleteProduct(
                            product._id,
                            product,
                            getProductData
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="13" style={{ color: "#2e6ca4" }}>
                    No Product Posted!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default AdminDashboard;
