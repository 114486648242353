import React, { useState, useEffect } from "react";
import API from "../../api/API.js";
import axios from "axios";
import { toast } from "react-toastify";

const Timeline = ({ order, onStatusChange, onBookingDataUpdate }) => {
  const [orderStatus, setOrderStatus] = useState(order.orderStatusDates || {});

  const [bookingId, setBookingId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [bookingData, setBookingData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (order) {
      setBookingId(order.bookingId || "");
      setServiceName(order.serviceName || "");
    }
  }, [order]);

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleBookingIdChange = (e) => {
    setBookingId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const booking = {
      orderID: order.orderID,
      bookingId: bookingId,
      serviceName: serviceName,
    };
    try {
      // console.log(booking);
      const response = await axios.put(`${API}/admin/booking`, { booking });
      const bookingData = response.data.booking;
      setBookingData(bookingData);
      // console.log("Booking ID submitted:", bookingData);

      // Send booking data to parent component
      onBookingDataUpdate(bookingData);
      // Reset the form
      // setBookingId(bookingData.bookingId);
      // setServiceName(bookingData.serviceName);
      setServiceName(order.serviceName || bookingData.serviceName);
      setBookingId(order.bookingId || bookingData.bookingId);
      toast.success("Booking created successfully")
    } catch (error) {
      console.error("Error submitting booking:", error);
      toast.error("Error submitting booking: " + error.message);
    } finally {
      setIsSubmitting(false); // Set loading state back to false
    }
  };

  const handleBookingIdClick = () => {
    navigator.clipboard.writeText(order.bookingId || bookingData.bookingId);
    toast.info("Booking ID copied to clipboard");
  };

  const handleStatusChange = (status) => {
    const updatedStatus = {
      ...orderStatus,
      [status]: new Date().toISOString(),
    };
    setOrderStatus(updatedStatus);
    onStatusChange(status);
  };

  useEffect(() => {
    // Any side effect logic based on orderStatus changes
    // console.log("Order status changed", orderStatus);
  }, [orderStatus]);

  const getStatusClass = (currentStatus, status) => {
    const statusOrder = [
      "orderPicked",
      "orderPacked",
      "orderDespatched",
      "bookingSuccessful",
    ];
    const currentStatusIndex = statusOrder.indexOf(currentStatus);
    const statusIndex = statusOrder.indexOf(status);

    // return statusIndex <= currentStatusIndex ? "completed green" : "";
  };

  return (
    <>
      <div className="timeline">
        <div
          className={`timeline-event ${getStatusClass(
            "orderPicked",
            "orderPicked"
          )}`}
        >
          <div className="timeline-content">
            <p className="name">
              Order <br />
              Picked
            </p>
          </div>
          <div
            className="timeline-marker"
            style={{ backgroundColor: orderStatus.orderPicked ? "green" : "" }}
          ></div>
          <input
            type="checkbox"
            className="status-checkbox"
            checked={!!orderStatus?.orderPicked}
            onChange={() => handleStatusChange("orderPicked")}
            disabled={!!orderStatus?.orderPicked}
          />
          <div className="date-time">
            {orderStatus?.orderPicked && (
              <p className="date">
                {new Date(orderStatus.orderPicked).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                <br />
                {new Date(orderStatus.orderPicked).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            )}
          </div>
        </div>
        <div
          className={`timeline-event ${getStatusClass(
            "orderPacked",
            "orderPacked"
          )}`}
        >
          <div className="timeline-content">
            <p className="name">
              Order <br />
              Packed
            </p>
          </div>
          <div
            className="timeline-marker"
            style={{ backgroundColor: orderStatus.orderPacked ? "green" : "" }}
          ></div>
          <input
            type="checkbox"
            className="status-checkbox"
            checked={!!orderStatus?.orderPacked}
            onChange={() => handleStatusChange("orderPacked")}
            disabled={!orderStatus?.orderPicked || !!orderStatus?.orderPacked}
          />
          <div className="date-time">
            {orderStatus?.orderPacked && (
              <p className="date">
                {new Date(orderStatus.orderPacked).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                <br />
                {new Date(orderStatus.orderPacked).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            )}
          </div>
        </div>
        <div
          className={`timeline-event ${getStatusClass(
            "orderDespatched",
            "orderDespatched"
          )}`}
        >
          <div className="timeline-content">
            <p className="name">
              Order <br />
              Despatched
            </p>
          </div>
          <div
            className="timeline-marker"
            style={{
              backgroundColor: orderStatus.orderDespatched ? "green" : "",
            }}
          ></div>
          <input
            type="checkbox"
            className="status-checkbox"
            checked={!!orderStatus?.orderDespatched}
            onChange={() => handleStatusChange("orderDespatched")}
            disabled={
              !orderStatus?.orderPacked || !!orderStatus?.orderDespatched
            }
          />
          <div className="date-time">
            {orderStatus?.orderDespatched && (
              <p className="date">
                {new Date(orderStatus.orderDespatched).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )}
                <br />
                {new Date(orderStatus.orderDespatched).toLocaleTimeString(
                  "en-US",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </p>
            )}
          </div>
        </div>
        <div
          className={`timeline-event ${getStatusClass(
            "bookingSuccessful",
            "bookingSuccessful"
          )}`}
        >
          <div className="timeline-content">
            <p className="name">
              Booking <br />
              Successful
            </p>
          </div>
          <div
            className="timeline-marker"
            style={{
              backgroundColor: orderStatus.bookingSuccessful ? "green" : "",
            }}
          ></div>
          <input
            type="checkbox"
            className="status-checkbox"
            checked={!!orderStatus?.bookingSuccessful}
            onChange={() => handleStatusChange("bookingSuccessful")}
            disabled={
              !orderStatus?.orderDespatched || !!orderStatus?.bookingSuccessful
            }
          />
          <div className="date-time">
            {orderStatus?.bookingSuccessful && (
              <p className="date">
                {new Date(orderStatus.bookingSuccessful).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )}
                <br />
                {new Date(orderStatus.bookingSuccessful).toLocaleTimeString(
                  "en-US",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </p>
            )}
          </div>
        </div>
      </div>
      <div>
        {(order.serviceName || bookingData.serviceName) &&
        (order.bookingId || bookingData.bookingId) ? (
          // If bookingData is available, display Service Name and Booking ID
          <div>
            <h5>
              Service Name: {order.serviceName || bookingData.serviceName}
            </h5>
            <h5>
              Booking ID:{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={handleBookingIdClick}
              >
                {order.bookingId || bookingData.bookingId}
              </span>
            </h5>
          </div>
        ) : (
          // If bookingData is not available, display the form
          <>
            {orderStatus?.bookingSuccessful && (
              <form
                onSubmit={handleSubmit}
                style={{
                  maxWidth: "400px",
                  margin: "0 auto",
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              >
                <div style={{ marginBottom: "5px" }}>
                  <label
                    htmlFor="serviceName"
                    style={{ display: "block", marginBottom: "5px" }}
                  >
                    Service Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Service Name"
                    name="serviceName"
                    list="service-names"
                    value={serviceName}
                    onChange={handleServiceNameChange}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required
                  />
                  <datalist id="service-names">
                    <option value="Professional Courier" />
                    <option value="Blue Dart Express Limited" />
                    <option value="ST Courier" />
                    <option value="Rathimeena Speed Parcel Service" />
                    <option value="ARC PARCEL SERVICE PRIVATE LIMITED" />
                    <option value="MSS parcel service" />
                    <option value="Ecom express pvt ltd" />
                    <option value="DTDC COURIER SIRKALI" />
                  </datalist>
                  <label
                    htmlFor="bookingId"
                    style={{ display: "block", marginBottom: "5px" }}
                  >
                    Booking ID
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Booking ID"
                    name="bookingId"
                    value={bookingId}
                    onChange={handleBookingIdChange}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required
                  />
                </div>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "none",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontSize: "16px",
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Timeline;
