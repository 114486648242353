import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Alert,
  Toast,
} from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { Checkbox, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import API from "../../api/API";
import bg from "../../assests/bg01.jpg";

const CreateSchemaValidation = yup.object({
  password: yup.string().required("Minimum 8 Characters Required").min(8),
  confirmPassword: yup.string().required("Password Doesn't Match").min(8),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: CreateSchemaValidation,
      onSubmit: (val) => {
        Create(val);
      },
    });

  const Create = async (val) => {
    // console.log(val);
    let { password } = val;
    // console.log(password);
    let payload = { password };
    let resetToken = localStorage.getItem("resetToken");
    try {
      setLoading(true);
      let res = await axios.post(`${API}/admin/reset-password`, payload, {
        headers: { Authorization: `Bearer ${resetToken}` },
      });
      // console.log(res);
      toast.success(res.data.message);
      localStorage.removeItem("resetToken");
      navigate("/admin");
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100vw" }}>
          <LinearProgress />
        </Box>
      ) : (
        " "
      )}
      <section className="h-100 gradient-custom-2 section">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div className="card" style={{ padding: "30px" }}>
                <div className="login-mai" style={{ height: "" }}>
                  <div className="form-outer">
                    <Form className="form" onSubmit={handleSubmit}>
                      <div style={{ textAlign: "center" }}>
                        <h2 style={{}}>Reset Your Password Here</h2>
                      </div>
                      <div
                        className="login-fields"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <TextField
                          label="Password"
                          type={show ? "text" : "password"}
                          variant="outlined"
                          onBlur={handleBlur}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          style={{
                            marginTop: "20px",
                            fontSize: "15px",
                          }}
                        />
                        {touched.password && errors.password ? (
                          <p style={{ color: "red" }}>{errors.password}</p>
                        ) : (
                          ""
                        )}

                        <TextField
                          label="Confirm Password"
                          type={show ? "text" : "password"}
                          variant="outlined"
                          onBlur={handleBlur}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          style={{
                            marginTop: "20px",
                            fontSize: "15px",
                          }}
                        />
                        {touched.confirmPassword && errors.confirmPassword ? (
                          <p style={{ color: "red" }}>
                            {"Enter the Confirm Password"}
                          </p>
                        ) : (
                          ""
                        )}

                        {values.confirmPassword.length &&
                        values.password !== values.confirmPassword ? (
                          <p style={{ color: "red" }}>
                            {"Password Doesn't Match"}
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="checkbox-div">
                          <Checkbox onClick={() => setShow(!show)} />
                          <p>Show Password</p>
                        </div>
                        <Button
                          className="btn"
                          style={{
                            borderRadius: "20px",
                          }}
                          variant="primary"
                          type="submit"
                          // onClick={() => login()}
                        >
                          Create
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
