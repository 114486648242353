import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { ImYoutube } from "react-icons/im";
import { SiInstagram } from "react-icons/si";
import { ImFacebook2 } from "react-icons/im";

const currentYear = new Date().getFullYear();
export default function Footer() {
  const navigate = useNavigate();


  const handleNavigation = (type) => {
    navigate(`/policy?type=${encodeURIComponent(type)}`);
  };
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start "
      style={{ color: "#1B1A55" }}
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a
            href="https://youtube.com/@salluuu_farming?si=R28ZJTveyxL8JIFg"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4 text-reset"
          >
            <ImYoutube />
          </a>
          <a
            href="https://www.instagram.com/salluuu_farming?igsh=MTd1eG43cnZzZnV4Mw%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4 text-reset"
          >
            <SiInstagram />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a> */}
          <a
            href="https://m.facebook.com/profile.php?id=100067189053375&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4 text-reset"
          >
            <ImFacebook2 />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a> */}
        </div>
      </section>

      <section className="">
  <MDBContainer className="text-center text-md-start mt-5">
    <MDBRow className="mt-3">
      <MDBCol md="6" lg="4" xl="4" className="mx-auto mb-3">
        <h6 className="text-uppercase fw-bold mb-4" style={{ textAlign: "center" }}>
          Salluuu Pets
        </h6>
        <h6 className="text-uppercase fw-bold mb-4" style={{ textAlign: "center" }}>
          Products
        </h6>
        <div className="d-flex justify-content-around flex-wrap">
          <div className="column m-2">
            <p>
              <Link to="/fish" className="text-reset">
                Fish
              </Link>
            </p>
            <p>
              <Link to="/birds" className="text-reset">
                Birds
              </Link>
            </p>
            <p>
              <Link to="/cat" className="text-reset">
                Cat
              </Link>
            </p>
            <p>
              <Link to="/dog" className="text-reset">
                Dog
              </Link>
            </p>
          </div>
          <div className="column m-2">
            <p>
              <Link to="/hamster" className="text-reset">
                Hamster
              </Link>
            </p>
            <p>
              <Link to="/pigeon" className="text-reset">
                Pigeon
              </Link>
            </p>
            <p>
              <Link to="/rabbit" className="text-reset">
                Rabbit
              </Link>
            </p>
            <p>
              <Link to="/poultry" className="text-reset">
                Poultry
              </Link>
            </p>
          </div>
        </div>
      </MDBCol>

      <MDBCol md="6" lg="4" xl="4" className="mx-auto mb-4">
        <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
        <p>
          <Link to="/explore" className="text-reset">
            Explore
          </Link>
        </p>
        <p>
          <Link to="/aboutus" className="text-reset">
            About Us
          </Link>
        </p>
        <p>
          <Link to="/socialmedia" className="text-reset">
            Social Media
          </Link>
        </p>
        <p>
          <Link to="/faq" className="text-reset">
            FAQ
          </Link>
        </p>
        <p>
          <Link to="/login" className="text-reset">
            Login
          </Link>
        </p>
      </MDBCol>

      <MDBCol md="6" lg="4" xl="4" className="mx-auto mb-md-0 mb-4">
        <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
        <p>
          <SiHomeassistantcommunitystore className="me-3" />
          Sirkazhi
        </p>
        <p>
          <MdEmail className="me-3" />
          <a href="mailto:md.salmanfaris03@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: "#1B1A55" }}>
            md.salmanfaris03@gmail.com
          </a>
        </p>
        <p>
          <IoCallSharp className="me-3" /> + 91 8438 270183
        </p>
        <p>
          <RiWhatsappFill className="me-3" /> + 91 8438 270183
        </p>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</section>

      <div className="container-fluid d-flex justify-content-evenly flex-wrap text-center p-4" style={{ gap: "2rem" }}>
      <div className="footer-link" onClick={() => handleNavigation('1')}>
        Terms & Conditions
      </div>
      <div className="footer-link" onClick={() => handleNavigation('2')}>
        Return & Refund Policy
      </div>
      <div className="footer-link" onClick={() => handleNavigation('3')}>
        Privacy Policy
      </div>
      <div className="footer-link" onClick={() => handleNavigation('4')}>
        Shipping & Payment Policy
      </div>
    </div>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © {currentYear} Copyright: 
        <a
          className="text-reset fw-bold"
          href="https://portfolio1-mohamed-salman-faris.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Powered by Mohamed Salman Faris
        </a>
      </div>
    </MDBFooter>
  );
}
