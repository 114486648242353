import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavbarComponent from "../navbar/Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Policy = () => {
  const [cart, setCart] = useState([]);

  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const divRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    console.log("location.search:", location.search); // Debugging line
    const type = query.get("type") || ""; // Changed from page_type to type
    console.log("Query param type:", type); // Debugging line
    setSearchTerm(type);
  }, [location.search]);

  useEffect(() => {
    if (searchTerm) {
      const scrollToDiv = divRefs[searchTerm];
      console.log("Scroll to div:", scrollToDiv); // Debugging line
      if (scrollToDiv && scrollToDiv.current) {
        console.log("Scrolling to div with id:", searchTerm); // Debugging line
        const offsetTop =
          scrollToDiv.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: offsetTop - 90, // Adjust this value to add more space above the target section
          behavior: "smooth",
        });
      } else {
        console.log("No div found for id:", searchTerm); // Debugging line
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <Helmet>
        <title>Salluuu Pets: Terms, Returns, Privacy & Shipping Policies</title>
        <meta
          name="description"
          content="Explore Salluuu Pets' policies on terms, shipping, returns, exchanges, and privacy. Learn how we ensure a transparent and trustworthy shopping experience for your pet needs."
        />
      </Helmet>
      <NavbarComponent totalQuantityInCart={totalQuantityInCart} />
      <h1
        style={{
          marginTop: "90px",
        }}
      >
        Policy
      </h1>
      <div
        className="policy-container"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div className="1 order-item" ref={divRefs[1]}>
          <h2 className="policy-text">Terms & Conditions</h2>
          <p className="policy-text">Updated at 2024-06-01</p>
          <hr></hr>
          <h4 className="policy-text">General Terms</h4>
          <p className="policy-text">
            By accessing and placing an order with Salluuu Pets, you confirm
            that you are in agreement with and bound by the terms of service
            contained in the Terms & Conditions outlined below. These terms
            apply to the entire website and any email or other type of
            communication between you and Salluuu Pets. Under no circumstances
            shall Salluuu Pets team be liable for any direct, indirect, special,
            incidental, or consequential damages, including, but not limited to,
            loss of data or profit, arising out of the use, or the inability to
            use, the materials on this site, even if Salluuu Pets team or an
            authorized representative has been advised of the possibility of
            such damages. If your use of materials from this site results in the
            need for servicing, repair, or correction of equipment or data, you
            assume any costs thereof. Salluuu Pets will not be responsible for
            any outcome that may occur during the course of usage of our
            resources. We reserve the right to change prices and revise the
            resources usage policy at any moment without any prior intimation /
            notice.
          </p>
          <h4 className="policy-text">License</h4>
          <p className="policy-text">
            Salluuu Pets grants you a revocable, non-exclusive,
            non-transferable, limited license to download, install and use the
            website strictly in accordance with the terms of this Agreement.
            These Terms & Conditions are a contract between you and Salluuu Pets
            (referred to in these Terms & Conditions as "Salluuu Pets", "us",
            "we" or "our"), the provider of the Salluuu Pets website and the
            services accessible from the Salluuu Pets website (which are
            collectively referred to in these Terms & Conditions as the "Salluuu
            Pets Service"). You are agreeing to be bound by these Terms &
            Conditions. If you do not agree to these Terms & Conditions, please
            do not use the Salluuu Pets Service. In these Terms & Conditions,
            "you" refers both to you as an individual and to the entity you
            represent. If you violate any of these Terms & Conditions, we
            reserve the right to cancel your account or block access to your
            account without notice.
          </p>
          <h4 className="policy-text">Definitions and key terms</h4>
          <p className="policy-text">
            To help explain things as clearly as possible in this Terms &
            Conditions, every time any of these terms are referenced, are
            strictly defined as:
          </p>
          <h4 className="policy-text">Cookie:</h4>
          <p className="policy-text">
            small amount of data generated by a website and saved by your web
            browser. It is used to identify your browser, provide analytics,
            remember information about you such as your language preference or
            login information.
          </p>
          <h4 className="policy-text">Company:</h4>
          <p className="policy-text">
            when these terms mention “Company,” “we,” “us,” or “our,” it refers
            to Salluuu Pets, that is responsible for your information under this
            Terms & Conditions.
          </p>
          <h4 className="policy-text">Country:</h4>
          <p className="policy-text">
            where Salluuu Pets or the owners/founders of Salluuu Pets are based,
            in this case is India.
          </p>
          <h4 className="policy-text">Device:</h4>
          <p className="policy-text">
            any internet-connected device such as a phone, tablet, computer, or
            any other device that can be used to visit Salluuu Pets and use the
            services.
          </p>
          <h4 className="policy-text">Service:</h4>
          <p className="policy-text">
            refers to the service provided by Salluuu Pets as described in the
            relative terms (if available) and on this platform.
          </p>
          <h4 className="policy-text">Third-party service: </h4>
          <p className="policy-text">
            refers to advertisers, contest sponsors, promotional and marketing
            partners, and others who provide our content or whose products or
            services we think may interest you.
          </p>
          <h4 className="policy-text">Website:</h4>
          <p className="policy-text">
            Salluuu Pets’s site, which can be accessed via this :
            https://salluuupets.online/
          </p>
          <h4 className="policy-text">You:</h4>
          <p className="policy-text">
            a person or entity that is registered with Salluuu Pets to use the
            Services.
          </p>
          <h4 className="policy-text">Restrictions</h4>
          <p className="policy-text">
            You agree not to, and you will not permit others to: a) License,
            sell, rent, lease, assign, distribute, transmit, host, outsource,
            disclose or otherwise commercially exploit the website or make the
            platform available to any third party. b) Modify, make derivative
            works of, disassemble, decrypt, reverse compile or reverse engineer
            any part of the website. c) Remove, alter or obscure any proprietary
            notice (including any notice of copyright or trademark) of Salluuu
            Pets or its affiliates, partners, suppliers or the licensors of the
            website.
          </p>
          <h4 className="policy-text">Payment</h4>
          <p className="policy-text">
            If you pay for any of our one-time payment plans, you agree to pay
            all fees or charges to your account for the Service in accordance
            with the fees, charges, and billing terms in effect at the time that
            each fee or charge is due and payable. Your Payment Provider
            agreement governs your use of the designated credit card account,
            and you must refer to that agreement and not these Terms to
            determine your rights and liabilities with respect to your Payment
            Provider. By providing Salluuu Pets with your credit card number and
            associated payment information, you agree that Salluuu Pets is
            authorized to verify information immediately, and subsequently
            invoice your account for all fees and charges due and payable to
            Salluuu Pets hereunder and that no additional notice or consent is
            required. You agree to immediately notify Salluuu Pets of any change
            in your billing address or the credit card or payment mode used for
            payment hereunder. Salluuu Pets reserves the right at any time to
            change its prices and billing methods, either immediately upon
            posting on our Site or by e-mail delivery to your organization’s
            administrator(s). Any attorney fees, court costs, or other costs
            incurred in the collection of delinquent undisputed amounts shall be
            the responsibility of and paid for by you. No contract will exist
            between you and Salluuu Pets for the Service until Salluuu Pets
            accepts your order by a confirmatory e-mail, SMS/MMS message, or
            other appropriate means of communication. You are responsible for
            any third-party fees that you may incur when using the Service.
          </p>

          <h4 className="policy-text">Your Suggestions</h4>
          <p className="policy-text">
            Any feedback, comments, ideas, improvements or suggestions
            (collectively, "Suggestions") provided by you to Salluuu Pets with
            respect to the website shall remain the sole and exclusive property
            of Salluuu Pets. Salluuu Pets shall be free to use, copy, modify,
            publish, or redistribute the Suggestions for any purpose and in any
            way without any credit or any compensation to you.
          </p>

          <h4 className="policy-text">Your Consent</h4>
          <p className="policy-text">
            We've updated our Terms & Conditions to provide you with complete
            transparency into what is being set when you visit our site and how
            it's being used. By using our website, registering an account, or
            making a purchase, you hereby consent to our Terms & Conditions.
          </p>

          <h4 className="policy-text">Links to Other Websites</h4>
          <p className="policy-text">
            These Terms & Conditions apply only to the Services provided on our
            Website. The Services may contain links to other websites not
            operated or controlled by Salluuu Pets. We are not responsible for
            the content, accuracy, or opinions expressed in such websites, and
            such websites are not investigated, monitored, or checked for
            accuracy or completeness by us. Please remember that when you use a
            link to go from the Services to another website, our Terms &
            Conditions are no longer in effect. Your browsing and interaction on
            any other website, including those that have a link on our platform,
            is subject to that website’s own rules and policies. Such third
            parties may use their own cookies or other methods to collect
            information about you.
          </p>

          <h4 className="policy-text">Cookies</h4>
          <p className="policy-text">
            Salluuu Pets uses "Cookies" to identify the areas of our website
            that you have visited. A cookie is a small piece of data stored on
            your computer or mobile device by your web browser. We use cookies
            to enhance the performance and functionality of our website but are
            non-essential to their use. However, without these cookies, certain
            functionality like videos may become unavailable or you would be
            required to enter your login details every time you visit the
            website as we would not be able to remember that you had logged in
            previously. Most web browsers can be set to disable the use of
            Cookies. However, if you disable Cookies, you may not be able to
            access functionality on our website correctly or at all. We never
            place Personally Identifiable Information in Cookies.
          </p>

          <h4 className="policy-text">Modifications to Our website</h4>
          <p className="policy-text">
            Salluuu Pets solely reserves the right to modify, suspend or
            discontinue, temporarily or permanently, the website or any service
            to which it connects, with or without notice and without liability
            to you.
          </p>

          <h4 className="policy-text">Updates to Our website</h4>
          <p className="policy-text">
            Salluuu Pets may from time to time provide enhancements or
            improvements to the features/ functionality of the wWebsite, which
            may include patches, bug fixes, updates, upgrades and other
            modifications ("Updates"). Updates may modify or delete certain
            features and/or functionalities of the website. You agree that
            Salluuu Pets has no obligation to (i) provide any Updates, or (ii)
            continue to provide or enable any particular features and/or
            functionalities of the website to you. You further agree that all
            Updates will be (i) deemed to constitute an integral part of the
            website, and (ii) subject to the terms and conditions of this
            Agreement.
          </p>

          <h4 className="policy-text">Third-Party Services</h4>
          <p className="policy-text">
            We may display, include or make available third-party content
            (including data, information, applications, and other products
            services) or provide links to third-party websites or services
            ("Third-Party Services"). You acknowledge and agree that Salluuu
            Pets shall not be responsible for any Third-Party Services,
            including their accuracy, completeness, timeliness, validity,
            copyright compliance, legality, decency, quality, or any other
            aspect thereof. Salluuu Pets does not assume and shall not have any
            liability or responsibility to you or any other person or entity for
            any Third-Party Services. Third-Party Services and links thereto are
            provided solely as a convenience to you and you access and use them
            entirely at your own risk and subject to such third parties terms
            and conditions.
          </p>

          <h4 className="policy-text">Term and Termination</h4>
          <p className="policy-text">
            This Agreement shall remain in effect until terminated by you or
            Salluuu Pets. Salluuu Pets may, in its sole discretion, at any time
            and for any or no reason, suspend or terminate this Agreement with
            or without prior notice. This Agreement will terminate immediately,
            without prior notice from Salluuu Pets, in the event that you fail
            to comply with any provision of this Agreement. You may also
            terminate this Agreement by deleting the website and all copies
            thereof from your computer. Upon termination of this Agreement, you
            shall cease all use of the wWebsite and delete all copies of the
            website from your computer. Termination of this Agreement will not
            limit any of Salluuu Pets's rights or remedies at law or in equity
            in case of breach by you (during the term of this Agreement) of any
            of your obligations under the present Agreement.
          </p>

          <h4 className="policy-text">Copyright Infringement Notice</h4>
          <p className="policy-text">
            If you are a copyright owner or such owner’s agent and believe any
            material on our Website constitutes an infringement on your
            copyright, please contact us setting forth the following
            information: (a) a physical or electronic signature of the copyright
            owner or a person authorized to act on his behalf; (b)
            identification of the material that is claimed to be infringing; (c)
            your contact information, including your address, telephone number,
            and an email; (d) a statement by you that you have a good faith
            belief that use of the material is not authorized by the copyright
            owners; and (e) the statement that the information in the
            notification is accurate, and, under penalty of perjury you are
            authorized to act on behalf of the owner.
          </p>

          <h4 className="policy-text">Indemnification</h4>
          <p className="policy-text">
            You agree to indemnify and hold Salluuu Pets and its parents,
            subsidiaries, affiliates, officers, employees, agents, partners, and
            licensors (if any) harmless from any claim or demand, including
            reasonable attorneys' fees, due to or arising out of your: (a) use
            of the website; (b) violation of this Agreement or any law or
            regulation; or (c) violation of any right of a third party.
          </p>

          <h4 className="policy-text">No Warranties</h4>
          <p className="policy-text">
            The Website is provided to you "AS IS" and "AS AVAILABLE" and
            without warranty of any kind. To the maximum extent permitted under
            applicable law, Salluuu Pets, on its own behalf and on behalf of its
            affiliates and its and their respective licensors and service
            providers, expressly disclaims all warranties, whether express,
            implied, statutory, or otherwise, with respect to the wWebsite,
            including all implied warranties of merchantability, fitness for a
            particular purpose, title and non-infringement, and warranties that
            may arise out of a course of dealing, course of performance, usage
            or trade practice. Without limitation to the foregoing, Salluuu Pets
            provides no warranty or undertaking, and makes no representation of
            any kind that the website will meet your requirements, achieve any
            intended results, be compatible or work with any other software,
            websites, systems or services, operate without interruption, meet
            any performance or reliability standards or be error-free or that
            any errors or defects can or will be corrected. Without limiting the
            foregoing, neither Salluuu Pets nor any Salluuu Pets's service
            provider makes any representation or warranty of any kind, express
            or implied: (i) as to the operation or availability of the wWebsite,
            or the information, content, and materials or products included
            thereon; (ii) that the wWebsite will be uninterrupted or error-free;
            (iii) as to the accuracy, reliability, or currency of any
            information or content provided through the wWebsite; or (iv) that
            the wWebsite, its servers, the content, or e-mails sent from or on
            behalf of Salluuu Pets are free of viruses, scripts, trojan horses,
            worms, malware, timebombs or other harmful components.
          </p>

          <h4 className="policy-text">Limitation of Liability</h4>
          <p className="policy-text">
            Notwithstanding any damages that you might incur, the entire
            liability of Salluuu Pets and any of its suppliers under any
            provision of this Agreement and your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by you onfor
            the Website. To the maximum extent permitted by applicable law, in
            no event shall Salluuu Pets or its suppliers be liable for any
            special, incidental, indirect, or consequential damages whatsoever
            (including, but not limited to, damages for loss of profits, for
            loss of data or other information, for business interruption, for
            personal injury, for loss of privacy arising out of or in any way
            related to the use of or inability to use the Website, third-party
            software and/or third-party hardware used with the wWebsite, or
            otherwise in connection with any provision of this Agreement), even
            if Salluuu Pets or any supplier has been advised of the possibility
            of such damages and even if the remedy fails of its essential
            purpose.
          </p>

          <h4 className="policy-text">Severability</h4>
          <p className="policy-text">
            If any provision of this Agreement is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect. This Agreement, together with the
            Privacy Policy and any other policies published by Salluuu Pets on
            the Sservices, shall constitute the entire agreement between you and
            Salluuu Pets concerning the Sservices. If any provision of this
            Agreement is deemed invalid by a court of competent jurisdiction,
            the invalidity of such provision shall not affect the validity of
            the remaining provisions of this Agreement, which shall remain in
            full force and effect. No waiver of any term of this Agreement shall
            be deemed a further or continuing waiver of such term or any other
            term, and Salluuu Pets’s failure to assert any right or provision
            under this Agreement shall not constitute a waiver of such right or
            provision. YOU AND Salluuu Pets AGREE THAT ANY CAUSE OF ACTION
            ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE
            (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
            ACTION IS PERMANENTLY BARRED.
          </p>

          <h4 className="policy-text">Waiver</h4>
          <p className="policy-text">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Agreement shall not
            affect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute waiver of any subsequent breach. No failure to
            exercise and no delay in exercising, on the part of either party,
            any right or any power under this Agreement shall operate as a
            waiver of that right or power. Nor shall any single or partial
            exercise of any right or power under this Agreement preclude further
            exercise of that or any other right granted herein. In the event of
            a conflict between this Agreement and any applicable purchase or
            other terms, the terms of this Agreement shall govern and supersede
            any such terms.
          </p>

          <h4 className="policy-text">Amendments to this Agreement</h4>
          <p className="policy-text">
            Salluuu Pets reserves the right, at its sole discretion, to modify
            or replace this Agreement at any time without any prior notice /
            intimation. Hence we request you to check this Agreement from time
            to time By continuing to access or use our website after any
            revisions become effective, it shall be deemed that youyou agree to
            be bound by the revised terms. If you do not agree to the new terms,
            you are no longer authorized to use Salluuu Pets.
          </p>

          <h4 className="policy-text">Entire Agreement</h4>
          <p className="policy-text">
            The Agreement constitutes the entire agreement between you and
            Salluuu Pets regarding your use of the Website and supersedes all
            prior and contemporaneous written or oral agreements between you and
            Salluuu Pets. You may be subject to additional terms and conditions
            that apply when you use or purchase other Salluuu Pets's services,
            which Salluuu Pets will provide to you at the time of such use or
            purchase.
          </p>

          <h4 className="policy-text">Intellectual Property</h4>
          <p className="policy-text">
            The Website and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video and audio, and the design, selection, and
            arrangement thereof), are owned by Salluuu Pets, its licensors or
            other providers of such material and are protected by India and
            international copyright, trademark, patent, trade secret and other
            intellectual property or proprietary rights laws. The material may
            not be copied, modified, reproduced, downloaded or distributed in
            any way, in whole or in part, without the express prior written
            permission of Salluuu Pets, unless and except as is expressly
            provided in these Terms & Conditions. Any unauthorized use of the
            material is prohibited.
          </p>

          <h4 className="policy-text">Choice of Law and Venue</h4>
          <p className="policy-text">
            The Terms of Use and policies shall be expounded and construed with
            and by the laws of the India, regardless of its conflict to laws and
            rules in India. Disputes of any sort will be subject to the
            exclusive jurisdiction of the courts situated within New Delhi and
            the user hereby will have to comply with the jurisdiction of the
            courts of Delhi.
          </p>

          <h4 className="policy-text">Submissions and Privacy</h4>
          <p className="policy-text">
            In the event that you submit or post any ideas, creative
            suggestions, designs, photographs, information, advertisements,
            data, or proposals, including ideas for new or improved products,
            services, features, technologies, or promotions, you expressly agree
            that such submissions will automatically be treated as
            non-confidential and non-proprietary and will become the sole
            property of Salluuu Pets without any compensation or credit to you
            whatsoever. Salluuu Pets and its affiliates shall have no
            obligations with respect to such submissions or posts and may use
            the ideas contained in such submissions or posts for any purposes in
            any medium in perpetuity, including, but not limited to, developing
            manufacturing, and marketing products and services using such ideas.
          </p>

          <h4 className="policy-text">Promotions</h4>
          <p className="policy-text">
            Salluuu Pets may, from time to time, include contests, promotions,
            sweepstakes, or other activities (“Promotions”) that require you to
            submit material or information concerning yourself. Please note that
            all Promotions may be governed by separate rules that may contain
            certain eligibility requirements, such as restrictions as to age and
            geographic location. You are responsible to read all Promotions
            rules to determine whether or not you are eligible to participate.
            If you enter any Promotion, you agree to abide by and to comply with
            all Promotions Rules. Additional terms and conditions may apply to
            purchases of goods or services on or through the Services, which
            terms and conditions are made a part of this Agreement by this
            reference.
          </p>

          <h4 className="policy-text">Typographical Errors</h4>
          <p className="policy-text">
            In the event a product and/or service is listed at an incorrect
            price or with incorrect information due to typographical error, we
            shall have the right to refuse or cancel any orders placed for the
            product and/or service listed at the incorrect price. We shall have
            the right to refuse or cancel any such order whether or not the
            order has been confirmed and your credit card charged. If your
            credit card has already been charged for the purchase and your order
            is canceled, we shall immediately issue a credit to your credit card
            account or other payment accounts in the amount of the charge.
          </p>

          <h4 className="policy-text">Miscellaneous</h4>
          <p className="policy-text">
            Any waiver of any provision of these Terms & Conditions will be
            effective only if in writing and signed by an authorized
            representative of Salluuu Pets. Salluuu Pets will be entitled to
            injunctive or other equitable relief (without the obligations of
            posting any bond or surety) in the event of any breach or
            anticipatory breach by you. Salluuu Pets operates and controls the
            Salluuu Pets Service from its offices in India. The Service is not
            intended for distribution to or use by any person or entity in any
            jurisdiction or country where such distribution or use would be
            contrary to law or regulation. Accordingly, those persons who choose
            to access the Salluuu Pets Service from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable. These
            Terms & Conditions (which include and incorporate the Salluuu Pets
            Privacy Policy) contains the entire understanding, and supersedes
            all prior understandings, between you and Salluuu Pets concerning
            its subject matter, and cannot be changed or modified by you. The
            section headings used in this Agreement are for convenience only and
            will not be given any legal import.
          </p>

          <h4 className="policy-text">Disclaimer</h4>
          <p className="policy-text">
            Salluuu Pets is not responsible for any content, code or any other
            imprecision. Salluuu Pets does not provide warranties or guarantees.
            In no event shall Salluuu Pets be liable for any special, direct,
            indirect, consequential, or incidental damages or any damages
            whatsoever, whether in an action of contract, negligence or other
            torts, arising out of or in connection with the use of the Service
            or the contents of the Service. The Company reserves the right to
            make additions, deletions, or modifications to the contents of the
            Service at any time without prior notice. The Salluuu Pets Service
            and its contents are provided "as is" and "as available" without any
            warranty or representations of any kind, whether express or implied.
            Salluuu Pets is a distributor and not a publisher of the content
            supplied by third parties; as such, Salluuu Pets exercises no
            editorial control over such content and makes no warranty or
            representation as to the accuracy, reliability, or currency of any
            information, content, service or merchandise provided through or
            accessible via the Salluuu Pets Service. Without limiting the
            foregoing, Salluuu Pets specifically disclaims all warranties and
            representations in any content transmitted on or in connection with
            the Salluuu Pets Service or on sites that may appear as links on the
            Salluuu Pets Service, or in the products provided as a part of, or
            otherwise in connection with, the Salluuu Pets Service, including
            without limitation any warranties of merchantability, fitness for a
            particular purpose or non-infringement of third party rights. No
            oral advice or written information was given by Salluuu Pets or any
            of its affiliates, employees, officers, directors, agents, or the
            like will create a warranty. Price and availability information is
            subject to change without notice. Without limiting the foregoing,
            Salluuu Pets does not warrant that the Salluuu Pets Service will be
            uninterrupted, uncorrupted, timely, or error-free.
          </p>

          <h4 className="policy-text">Contact Us</h4>
          <p className="policy-text">
            Don't hesitate to contact us if you have any questions. Via Email:
            md.salmanfaris03@gmail.com Via Phone Number: 8438270183
          </p>
        </div>

        <div className="2 order-item" ref={divRefs[2]}>
          <h2 className="policy-text">Return & Refund Policy</h2>
          <p className="policy-text">Updated at 2024-06-01</p>
          <hr></hr>
          <p className="policy-text">
            We do not allow returns or refunds for any purchases made through
            our website. All sales are final and non-refundable.Goods once sold
            will not be taken back or exchanged
          </p>
        </div>

        <div className="3 order-item" ref={divRefs[3]}>
          <h2 className="policy-text">Privacy Policy</h2>
          <p className="policy-text">Updated at 2024-06-01</p>
          <hr></hr>
          <p className="policy-text">
            We take security and privacy with the utmost regard. Following is
            the information about the policies and procedures of Salluuu Pets.
            and the collection, usage, disclosure, and dissemination of
            information. By visiting this Website you agree to be bound by the
            terms and conditions of this Privacy Policy. If you do not agree
            please do not use or access our Wwebsite. By use of the Website, you
            expressly consent to our use and disclosure of your personal
            information in accordance with this Privacy Policy. Our privacy
            policy is subject to change at any time without notice. To make sure
            you are aware of any changes, please review this policy
            periodically. We receive information about you from various sources,
            including but not limited to: (i) if you register for the site and
            the offerings, through your user account on the offerings (your
            “Account”); (ii) your use of the offerings generally; and (iii) from
            third-party websites and services. When you use the offerings, you
            are consenting to the collection, transfer, manipulation, storage,
            disclosure, and other uses of your information as described in this
            Privacy Policy. This Privacy Policy covers the treatment of
            personally identifiable information (“Personal Information”)
            gathered when you are using or accessing the Offerings.
          </p>

          <h4 className="policy-text">Scope of Privacy Policy</h4>
          <p className="policy-text">
            This Privacy Policy does not apply to the practices of third parties
            that we do not own or control, including but not limited to any
            third-party websites, services, and applications (“Third Party
            Services”) that you elect to access through the Oofferings or to
            individuals that we do not manage or employ. We take an effort in
            selecting and facilitating access to service providers with high
            privacy and security standards. However, we cannot take
            responsibility for third party service providers. We urge you to
            carefully review the privacy policies of any Third Party Services
            you access.
          </p>

          <h4 className="policy-text">Information Collection</h4>
          <p className="policy-text">
            We collect information to enable us to provide a safe, personalized,
            and optimized experience to our users. It helps us improve and
            update the offerings for the users. We only collect information that
            we consider necessary to achieve this purpose. You can browse the
            website anonymously without revealing your identity or any personal
            information about yourself. Once you give us your personal
            information, you are not anonymous to us. Where possible, we
            indicate which fields are required and which fields are optional.
            You always have the option to not provide information by choosing
            not to use a particular service or feature on the website. On the
            usage of our website whether by logging in or simply surfing, we may
            collect behavioral information. We use this information to analyze
            users' demographics, interests, and behavior to better understand,
            protect and serve our users at aggregate levels. This information
            may include the URL from which the user was directed (whether this
            URL is on our website or not), the URL which the user leaves for
            (whether this URL is on our website or not), computer browser
            information, and the IP address. We use “cookies” for data
            collection. The information collected is used to analyze the
            effectiveness of the website features, webpage flow, and experience.
            Cookies also allow users to enter the password less frequently
            during a session. You may choose to disable the cookies and still
            use the website. This may limit certain useful features on the site.
            We do not control third-party cookies or similar elements on some
            pages of the website. We may collect different types of information
            on website usage. We collect information on buying behavior on
            purchases being made on the website. We also collect additional
            information, such as a billing address, a credit/debit card number,
            a credit/debit card expiration date, and/ or other payment
            instrument details and tracking information from cheques or money
            orders on transactions. We also collect information from discussions
            and message boards or feedback. We may retain this information as
            necessary to resolve disputes, provide customer support and
            troubleshoot problems as permitted by law. We may also collect
            information from personal correspondence such as letters and emails
            sent to us. We collect personally identifiable information (email
            address, name, phone number, credit card/debit card / other payment
            instrument details, etc.) from you when you set up a free account
            with us. While you can browse some sections of our website without
            being a registered member, certain activities (such as placing an
            order) do require registration. We do use your contact information
            to send you offers based on your previous orders and your interests.
          </p>

          <h4 className="policy-text">Account Information</h4>
          <p className="policy-text">
            When you create an Account, you will provide information that could
            be Personal Information, such as your username, password, and email
            address. You acknowledge that this information may be personal to
            you, and by creating an account on the website and providing
            Personal Information to us, you allow others, including us, to
            identify you and therefore may not be anonymous. We may use your
            contact information to send you information about our offerings, but
            only rarely when we feel such information is important. You may
            unsubscribe from these messages through your Account settings,
            although we, regardless, reserve the right to contact you when we
            believe it is necessary, such as for account recovery purposes.
            Usage of certain features on the website may require registration
            and sharing of information such as email address, name, phone
            number, credit card/debit card / other payment instrument details,
            etc. If you choose to buy products / services from the website, we
            collect information about your buying behavior. If you transact with
            us, we collect some additional information, such as a billing
            address, a credit/debit card number, a credit/debit card expiration
            date, and/ or other payment instrument details and tracking
            information from cheques or money orders. If you choose to post
            messages on our message boards, chat rooms, or other message areas
            or leave feedback, we will collect that information you provide to
            us. We retain this information as necessary to resolve disputes,
            provide customer support and troubleshoot problems as permitted by
            law. If you send us personal correspondence, such as emails or
            letters, or if other users or third parties send us correspondence
            about your activities or postings on the Website, we may collect
            such information into a file specific to you.
          </p>

          <h4 className="policy-text">Use of demographic and profile data</h4>
          <p className="policy-text">
            We use your personal information to resolve disputes; troubleshoot
            problems; help promote a safe service; collect money; measure
            consumer interest in our products and services, inform you about
            online and offline offers, products, services, and updates;
            customize your experience; detect and protect us against error,
            fraud, and other criminal activity; enforce our terms and
            conditions; and as otherwise described to you at the time of
            collection. We will occasionally ask you to complete optional online
            surveys. These surveys may ask you for contact information and
            demographic information (like pin code, age, education, or income).
            We use this data to tailor your experience at our website, providing
            you with content that we think you might be interested in and
            displaying the content according to your preferences.
          </p>

          <h4 className="policy-text">Sharing of personal information</h4>
          <p className="policy-text">
            We may share personal information with our other corporate entities
            and affiliates to help detect and prevent identity theft, fraud, and
            other potentially illegal acts; correlate related or multiple
            accounts to prevent abuse of our services, and facilitate joint or
            co-branded services that you request where such services are
            provided by more than one corporate entity. Those entities and
            affiliates may not market to you as a result of such sharing unless
            you are explicitly opt-in. We may disclose personal information if
            required to do so by law or in the good faith belief that such
            disclosure is reasonably necessary to respond to subpoenas, court
            orders, or another legal process. We may disclose personal
            information to law enforcement offices, third party rights owners,
            or others in the good faith belief that such disclosure is
            reasonably necessary to: (i) enforce our Terms or Privacy Policy;
            (ii) respond to claims that an advertisement, posting or other
            content violates the rights of a third party; or (iii) protect the
            rights, property or personal safety of our users or the general
            public. We may use third party service providers to provide website
            and application development, hosting, maintenance, backup, storage,
            payment processing, analysis and other services for us, which may
            require them to access or use information about you. If a service
            provider needs to access information about you to perform services
            on our behalf, they do so under close instruction from us, including
            policies and procedures designed to protect your information. All of
            our service providers and partners agree to strict confidentiality
            obligations.
          </p>

          <h4 className="policy-text">Links to Other Sites</h4>
          <p className="policy-text">
            Our website links to other websites that may collect personally
            identifiable information about you. Salluuu Pets is not responsible
            for the privacy practices or the content of those linked websites.
            Access or Control your Information: You have been provided with
            tools to manage your information. You have the right to access,
            rectify, update or delete the information that you provide to us by
            contacting ______. Steps taken by us to secure your Information: We
            follow generally accepted industry standards to protect the personal
            information submitted to us. All your information, not just the
            sensitive information mentioned above, is restricted in our offices.
            Only employees who need the information to perform a specific job
            are granted access to personally identifiable information. If you
            have any questions about the security at our website, please email
            us on the email id mentioned below. While we implement safeguards
            designed to protect your information, no security system is
            impenetrable and due to the inherent nature of the internet, we
            cannot guarantee that data, during transmission through the internet
            or while stored on our systems or otherwise in our care, is
            absolutely safe from intrusion by others. When our
            registration/order process asks you to enter sensitive information
            (such as a credit card number), such information is encrypted and is
            protected with the best encryption software in the industry.
            Information kept by us: We keep your personal information only as
            long as you use our service and also as permitted/required by
            applicable law. In practice, we delete or anonymize your information
            upon deletion of your account, unless the same is required for to
            comply with legal obligations, fraud prevention, take actions we
            deem necessary to protect the integrity of our Website and App or
            our users, to resolve disputes, to enforce our agreements, to
            support business operations, and to continue to develop and improve
            our services. We retain information for better services, and we only
            use the information to analyze about the use of our services, not to
            specifically analyze personal characteristics about you.
          </p>

          <h4 className="policy-text">Your Consent</h4>
          <p className="policy-text">
            By using the website and/ or by providing your information, you
            consent to the collection and use of the information you disclose on
            the website in accordance with this Privacy Policy, including but
            not limited to Your consent for sharing your information as per this
            privacy policy. If we decide to change our privacy policy, we will
            post those changes on this page so that you are always aware of what
            information we collect, how we use it, and under what circumstances
            we disclose it.
          </p>

          <h4 className="policy-text">Grievance Officer</h4>
          <p className="policy-text">
            In accordance with Information Technology Act 2000 and rules made
            thereunder, the name and contact details of the Grievance Officer
            are provided below: Salluuu Pets NO : 31/A KAMARAJAR STREET,
            SIRKAZHI. Phone: 8438270183 Email: md.salmanfaris03@gmail.com Time:
            Mon to Fri (9AM to 6PM)
          </p>

          <h4 className="policy-text">Questions?</h4>
          <p className="policy-text">
            Please contact us with any questions regarding this statement.
          </p>
        </div>

        <div className="4 order-item" ref={divRefs[4]}>
          <h2 className="policy-text">Shipping & Payment Policy</h2>
          <p className="policy-text">Updated at 2024-06-01</p>
          <hr></hr>
          <p className="policy-text">
            We endeavour to dispatch all products ordered within 48 hours after
            the order has been placed and accepted by us. You will be given an
            indication of the expected delivery time when you place your order
            online. Salluuu Pets insures each order through transit up until it
            is delivered to you or is collected. You need to sign a confirmation
            of receipt of the products when the products are collected and by
            doing so, you accept the responsibility for the products ordered
            from that moment on. If the recipient or collector is not the
            original purchaser, or in case of delivery of a gift, then you
            accept this signature as evidence of delivery and fulfillment of
            your order.
          </p>

          <p className="policy-text">
            DELIVERY CHARGES(based on selection) All domestic orders are
            delivered for free of charge
          </p>

          <p className="policy-text">
            ADDITIONAL CHARGES There are no additional charges. The total
            payable amount is indicated on the individual items.
          </p>

          <p className="policy-text">
            DELIVERY TIME This may vary depending on the delivery location and
            services of our logistics partner. However, we endeavour to deliver
            orders within 4 to 7 Business days (excludes public holidays)
          </p>

          <p className="policy-text">
            DELIVERY AREAS We deliver PAN India, For further information please
            call us on 8438270183 10 AM to 5 PM, Monday to Saturday on business
            days (excludes public holidays) or write to us at
            md.salmanfaris03@gmail.com
          </p>

          <p className="policy-text">
            PAYMENT MODE You can pay Online through Internet banking, Visa,
            MasterCard, American Express, Maestro, Debit cards, IMPS
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Policy;

const styles = {
  p: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
};
