import React from "react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div className="no-page">
      <Container className="mt-4 text-center">
        <Row className="align-items-center justify-content-center">
          <Col md={6}>
            <h1 className="display-1">404</h1>
            <h2 className="mb-4">Page Not Found</h2>
            <h5 className="mb-3">
              Oops! The page you are looking for does not exist. It might have
              been moved or deleted.
            </h5>
            <Link to="/">
              <Button variant="primary" size="lg" className="mb-3">
                Back to Home
              </Button>
            </Link>
            <p className="mb-3">Or explore these popular sections:</p>
            <div>
              <Link to="/explore" style={{ margin: "1rem" }}>
                <Button
                  variant="secondary"
                  className="mb-2"
                  style={{ width: "10rem" }}
                >
                  Explore
                </Button>
              </Link>
              <Link to="/aboutus" style={{ margin: "1rem" }}>
                <Button
                  variant="secondary"
                  className="mb-2"
                  style={{ width: "10rem" }}
                >
                  About Us
                </Button>
              </Link>
              <Link to="/socialMedia" style={{ margin: "1rem" }}>
                <Button
                  variant="secondary"
                  className="mb-2"
                  style={{ width: "10rem" }}
                >
                  Social Media
                </Button>
              </Link>
              <Link to="/faq" style={{ margin: "1rem" }}>
                <Button
                  variant="secondary"
                  className="mb-2"
                  style={{ width: "10rem" }}
                >
                  FAQ
                </Button>
              </Link>
            </div>
            <h5 className="mt-2 mb-3">
              Happy Shopping...
            </h5>
            {/* <p className="mt-4 mb-3">
              Still can't find what you're looking for? Try searching:
            </p> */}
            {/* <Form inline className="justify-content-center">
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary">Go</Button>
                </InputGroup.Append>
              </InputGroup>
            </Form> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoPage;
