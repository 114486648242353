import React from 'react';
import loader from "../assests/loading1.gif";

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={loader} alt="Loading..." />
    </div>
  );
};

export default Preloader;
