import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { Tooltip, OverlayTrigger, Dropdown, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import loader from "../../assests/loading1.gif";
import ImageLoader from "../collections/ImageLoader";

function ProductCard({ product, onAddToCart, setCart }) {
  const [quantity, setQuantity] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  // const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token || localStorage.getItem("token");

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the entered value is between 1 and 99
    if (value >= 1 && value <= 99) {
      setQuantity(value);
    }
  };

  const incrementQuantity = () => {
    if (quantity < 99) {
      setQuantity(quantity + 1);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
    // if (!token) {
    //   toast.warning("You need to Login First", { autoClose: 3000 });
    //   navigate("/login");
    //   return;
    // }

    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item.product._id === product._id
    );

    if (existingProductIndex !== -1) {
      // Product already exists in the cart, update quantity
      existingCart[existingProductIndex].quantity += quantity;
    } else {
      // Product not found in the cart, add it
      existingCart.push({ product, quantity });
    }
    setCart(existingCart);
    localStorage.setItem("cart", JSON.stringify(existingCart));
    toast.success("Product added to cart successfully", { autoClose: 2000 });
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Calculate discount percentage
  const discountPercentage = Math.round(
    ((product.mrpPrice - product.newPrice) / product.mrpPrice) * 100
  );

  return (
    <Card
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "15rem",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        margin: "10px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "100%", // This makes the container a square
          overflow: "hidden",
        }}
      >
        {!imageLoaded && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {/* <div style={{ width: "100%", height: "100%" }}>
              <Skeleton width={"100vw"} height={"100vh"} />
            </div> */}
            <div style={{ width: "100%", height: "100%" }}>
              <ImageLoader  />
            </div>
          </div>
        )}
        <Card.Img
          variant="top"
          src={product.productImage || loader}
          onLoad={handleImageLoad}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
            display: imageLoaded ? "block" : "none",
          }}
        />
      </div>
      {product.quantity === 0 && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Out of Stock
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          padding: "5px",
          borderBottomRightRadius: "8px",
        }}
      >
        {discountPercentage}% off
      </div>
      <Card.Body style={{ padding: "8px" }}>
        <Card.Title
          style={{
            margin: "2px",
            fontSize: "15px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            height: "40px",
          }}
        >
          {product.productTitle}
        </Card.Title>
        <Card.Text style={{ margin: "2px" }}>
          <span style={{ fontWeight: "bold", color: "#1679AB" }}>
            &#8377;{product.newPrice}
          </span>
          <span
            style={{
              textDecoration: "line-through",
              marginLeft: "15px",
              fontSize: "12px",
              color: "#344955",
            }}
          >
            &#8377;{product.mrpPrice}
          </span>
        </Card.Text>
        <Card.Text
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            margin: "4px",
            fontSize: "12px",
            color: "#344955",
          }}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${product._id}`}>
                {product.productContent}
              </Tooltip>
            }
          >
            <span>
              {product.productContent.length > 80
                ? product.productContent.slice(0, 80) + "..."
                : product.productContent}
            </span>
          </OverlayTrigger>
        </Card.Text>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outline-primary"
            onClick={decrementQuantity}
            style={{ borderRadius: "50px", margin: "0", padding: "0" }}
          >
            <FaMinus
              style={{ fontSize: "18px", margin: "4px", padding: "0" }}
            />
          </Button>
          <FormControl
            type="number"
            value={quantity}
            onChange={handleInputChange}
            style={{
              margin: "0 10px",
              textAlign: "center",
              width: "65px",
              border: "2px solid #386bc0",
              borderRadius: "10px",
              height: "30px",
            }}
            max={99}
          />
          <Button
            variant="outline-primary"
            onClick={incrementQuantity}
            style={{ borderRadius: "50px", margin: "0", padding: "0" }}
          >
            <FaPlus style={{ fontSize: "18px", margin: "4px", padding: "0" }} />
          </Button>
        </div>
        <Button
          variant="primary"
          onClick={handleAddToCart}
          style={{
            width: "175px",
            borderRadius: "50px",
            fontSize: "15px",
            marginBottom: "7px",
            padding: "7px 15px",
            background: "linear-gradient(90deg, #02c39a, #05668d)",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.background =
              "linear-gradient(90deg, #05668d, #02c39a)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.background =
              "linear-gradient(90deg, #02c39a, #05668d)";
          }}
        >
          <IoMdCart
            style={{
              fontSize: "17px",
              margin: "0px 5px 4px 0px",
              padding: "0",
            }}
          />
          Add to Cart
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;
