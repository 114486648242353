import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assests/logo001.jpg";
import { RiMenuFold3Line2 } from "react-icons/ri";
import { PiSignOutBold } from "react-icons/pi";
import { FaUser } from "react-icons/fa";
import { BiSolidDashboard } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TbReportAnalytics } from "react-icons/tb";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaUserSecret } from "react-icons/fa";

function AdminNavbar({ adminData }) {
  const navigate = useNavigate();
  const { userName, _id } = adminData;
  // console.log(userName, _id);
  const userID = _id;
  // const firstLetter = userData?.userName?.charAt(0).toUpperCase() || "";

  const handleSignOut = () => {
    // Implement your sign-out logic here
    console.log("Signing out...");
    const shouldLogout = window.confirm(`Are you sure you want to Logout ?`);
    if (shouldLogout) {
      localStorage.clear();
      toast.success("Logged out Successful");
      navigate("/admin");
    }
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary fixed-top"
        >
          <Container fluid>
            <Navbar.Brand>
              <img
                src={logo}
                width="50"
                height="50"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Brand style={{ color: "#00215E", fontSize: "28px" }}>
              Salluuu Pets
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              as="button" // Render the toggle as a button element
            >
              <RiMenuFold3Line2
                style={{
                  color: "#00215E",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              />{" "}
              {/* Include the icon inside the toggle */}
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Salluuu Pets
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">
                  <Link to={`/dashboard/${userID}`} className="nav-link">
                    <BiSolidDashboard
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontSize: "20px",
                      }}
                    />
                    Dashboard
                  </Link>
                  <Link to={`/addproduct/${userID}`} className="nav-link">
                    <IoMdAdd
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontSize: "24px",
                      }}
                    />
                    Add Products
                  </Link>
                  <Link to={`/orderdata/${userID}`} className="nav-link">
                    <TbReportAnalytics
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontSize: "24px",
                      }}
                    />
                    Order Data
                  </Link>
                  <Link to={`/userdata/${userID}`} className="nav-link">
                    <PiUsersThreeFill
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontSize: "24px",
                      }}
                    />
                    Users Data
                  </Link>
                </Nav>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
                <Navbar.Collapse className="justify-content-end">
                  <NavDropdown
                    title={
                      <>
                        <FaUserSecret
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "18px",
                          }}
                        />{" "}
                        {/* User icon */}
                        {userName} {/* User name */}
                      </>
                    }
                    id={`offcanvasNavbarDropdown-expand-md`}
                  >
                    <NavDropdown.Item >
                      View Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item >
                      Edit Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      style={{ fontSize: "20px" }}
                      onClick={handleSignOut}
                    >
                      <PiSignOutBold
                        style={{
                          marginRight: "5px",
                          marginBottom: "5px",
                          fontSize: "20px",
                        }}
                        type="submit"
                        onClick={() => handleSignOut()}
                      />
                      Log Out
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* Show only the avatar on smaller screens */}
                  {/* <div className="d-md-none">
                    <div
                      className="profile-avatar"
                      style={{ backgroundColor: "#007bff" }}
                    >
                      {firstLetter}
                    </div>
                  </div> */}

                  {/* Show only the username on larger screens */}
                  {/* <div className="d-none d-md-block">
                    Signed in as: {userData.userName}
                  </div> */}
                </Navbar.Collapse>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default AdminNavbar;
