// CarouselComponent.js
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import sun from "../../assests/sunconure2.JPG";
import pineapple from "../../assests/pineapple1.JPG";
import macaw from "../../assests/macaw1.JPG";
import cat from "../../assests/cat2.JPG";
import dog from "../../assests/dog1.JPG";
import hamster from "../../assests/hamster.JPG";
import fish from "../../assests/fish1.JPG";

function CarouselComponent() {
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // Increment slideIndex
            setSlideIndex((prevIndex) => (prevIndex + 1) % 7);
        }, 4000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const onChange = (index) => {
        // console.log('Slide changed to index', index);
    };

    const onClickItem = (index) => {
        // console.log('Clicked item with index', index);
    };

    const onClickThumb = (index) => {
        // console.log('Clicked thumb with index', index);
    };

    return (
        <Carousel 
            showArrows={true} 
            onChange={onChange} 
            onClickItem={onClickItem} 
            onClickThumb={onClickThumb} 
            selectedItem={slideIndex} // Set the current slide index
        >
            <div>
                <img src={sun} alt="Slide 1" title="Sun Conure" />
                {/* <p className="legend">Legend 1</p> */}
            </div>
            <div>
                <img src={pineapple} alt="Slide 2" title="Pineapple conure" />
                {/* <p className="legend">Legend 2</p> */}
            </div>
            <div>
                <img src={macaw} alt="Slide 3" title="Macaw" />
                {/* <p className="legend">Legend 3</p> */}
            </div>
            <div>
                <img src={cat} alt="Slide 4" title="Cat" />
                {/* <p className="legend">Legend 4</p> */}
            </div>
            <div>
                <img src={dog} alt="Slide 5" title="Dog" />
                {/* <p className="legend">Legend 5</p> */}
            </div>
            <div>
                <img src={hamster} alt="Slide 6" title="Hamster" />
                {/* <p className="legend">Legend 6</p> */}
            </div>
            <div>
                <img src={fish} alt="Slide 7" title="Fish" />
                {/* <p className="legend">Legend 6</p> */}
            </div>
        </Carousel>
    );
}

export default CarouselComponent;
