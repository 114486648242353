import React, { useState, useEffect } from "react";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";

const FAQ = () => {
  const [cart, setCart] = useState([]);
  const [openQuestion, setOpenQuestion] = useState(null);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const toggleQuestion = (questionIndex) => {
    setOpenQuestion(openQuestion === questionIndex ? null : questionIndex);
  };

  return (
    <>
      <Helmet>
        <title>Salluuu Pets FAQ: Transportation, Payment & Landmarks</title>
        <meta
          name="description"
          content="Find answers about transportation charges in Sirkazhi, accepted payment methods, and nearby landmarks for Salluuu Pets. Shop with ease for all your pet products and accessories."
        />
      </Helmet>
      <NavbarComponent totalQuantityInCart={totalQuantityInCart} />
      <div
        className="container-fluid faq-container"
        style={{ marginTop: "90px" }}
      >
        <h1 className="faq-title">Frequently Asked Questions</h1>
        <div className="faq-item" onClick={() => toggleQuestion(1)}>
          <div className="faq-question-container">
            <h3 className="faq-question">
              What are the transportation charges within Sirkazhi?
            </h3>
            <span className="faq-toggle-icon">
              {openQuestion === 1 ? "-" : "+"}
            </span>
          </div>
          {openQuestion === 1 && (
            <p className="faq-answer">
              Charges are based on the products. For example, one single 2 feet
              cage delivery to 20 KM will be charged Rs. 145 per single cage. If
              the customer buys two cages, then the charges will be Rs. 175. So,
              it's better to call 8438270183 for the delivery charges before
              placing the order. Our shop is located in Sirkazhi,
              Mayiladuthurai.
            </p>
          )}
        </div>
        <div className="faq-item" onClick={() => toggleQuestion(2)}>
          <div className="faq-question-container">
            <h3 className="faq-question">
              What are the various modes of payment accepted here?
            </h3>
            <span className="faq-toggle-icon">
              {openQuestion === 2 ? "-" : "+"}
            </span>
          </div>
          {openQuestion === 2 && (
            <p className="faq-answer">
              You can make payment via Cash, Master Card, Visa Card, Debit
              Cards, Credit Card, G Pay, PhonePe, and Paytm.
            </p>
          )}
        </div>
        <div className="faq-item" onClick={() => toggleQuestion(3)}>
          <div className="faq-question-container">
            <h3 className="faq-question">Which is the nearest landmark?</h3>
            <span className="faq-toggle-icon">
              {openQuestion === 3 ? "-" : "+"}
            </span>
          </div>
          {openQuestion === 3 && (
            <p className="faq-answer">
              Our shop is located near the Sirkazhi, close to Sirkazhi
              Municipality.
            </p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
