import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <Helmet>
        <title>About Salluuu Pets: Trusted Pet Products Delivery</title>
        <meta
          name="description"
          content="Nationwide delivery of pet products. No Cash on Delivery. Inform us via WhatsApp after payment. Fast delivery to major cities in India. Extra delivery charge."
        />
      </Helmet>
      <NavbarComponent totalQuantityInCart={totalQuantityInCart} />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="container-lg">
          <h2 className="heading" style={{ margin: "40px" }}>
            About Us
          </h2>
          <div className="gruppo-regular" style={{ fontSize: "25px" }}>
            <ul
              style={{
                listStyleType: "decimal",
                textAlign: "left",
                padding: "25px 25px",
                gap: "20px",
              }}
            >
              <li>NO CASH ON DELIVERY Even though it says cod.</li>
              <li>
                Transport delivery anywhere in India by courier in your doorstep
                (or ) parcel service .
              </li>
              <li>Delivery charge extra.</li>
              <li>If you transferred money, kindly inform us via WhatsApp .</li>
              <li>
                Product colour obtain not available at the time of available
                colour only we send .
              </li>
              <li>
                We are not responsible for any accidents, delays, damage or
                other issues, which are the responsibility of the shipping
                service.
              </li>
              <li>
                The shipment is not covered under insurance and transported at
                the buyer’s risk entirely.
              </li>
            </ul>
            <h3 style={{ textAlign: "left", fontWeight: "800" }}>
              Shipping Info:
            </h3>
            <p style={{ textAlign: "left" }}>
              Fast delivery of multiple varieties of pet food and accessories in
              Chennai, Bangalore, Mumbai, Delhi, Kolkata, Hyderabad, Cochin,
              Pune and all other major cities.
            </p>
          </div>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.2954215426466!2d79.73351777409377!3d11.239664850480343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a54df8f79656871%3A0xdbbdef4a81de257!2sSalluuu%20Farming!5e0!3m2!1sen!2sin!4v1714824700592!5m2!1sen!2sin"
          width="100%"
          height="600"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Salluuu Farming Map"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
