import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Alert,
  Toast,
} from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import API from "../../api/API";
import bg from "../../assests/bg01.jpg";

const LoginSchemaValidation = yup.object({
  email: yup.string().email().required("Please Enter A Valid Email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password Weak !"
    ),
});

const AdminLogin = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const token = localStorage.getItem("token");

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: LoginSchemaValidation,
      onSubmit: (val) => {
        UserLogin(val);
      },
    });

    const UserLogin = async (val) => {
      let { email, password } = val;
      let payload = { email, password };
      try {
        setLoading(true);
        const loginData = { email, password };
        const res = await axios.post(`${API}/admin/login`, loginData);
        const { message, user, token } = res.data;
        if (user.verified) {
          if (user.role === "admin") {
            toast.success(message);
            localStorage.setItem("token", token);
            navigate(`/dashboard/${user.id}`);
          } else {
            toast.error("You are not an admin.");
          }
        } else {
          toast.error(
            "Email not verified. Please check your email for the verification link."
          );
          setLoading(false);
          navigate("/resend-verification-email");
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          // Server returned an error response
          toast.error(error.response.data.message);
          setLoading(false);
        } else if (error.request) {
          // Request was made but no response received
          toast.error("Network error. Please try again later.");
          setLoading(false);
        } else {
          // Something else happened
          toast.error("An unexpected error occurred.");
          setLoading(false);
        }
        navigate("/admin");
        setLoading(false);
      }
    };
    

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      localStorage.clear();
    }
  }, []);

  return (
    <>
    {loading ? (
        <Box sx={{ width: "100vw", position:"fixed" }}>
          <LinearProgress />
        </Box>
      ) : (
        " "
      )}
      <section className="h-100 gradient-custom-2 section">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div className="card" style={{ padding: "30px" }}>
                <h3 style={{ fontSize: "2rem", fontWeight: "700", margin:"30px 10px" }}>
                  Admin Login
                </h3>
                <Form className="login-form" onSubmit={handleSubmit}>
                  <Form.Group className="mb-5">
                    {touched.email && errors.email ? (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    ) : (
                      ""
                    )}
                    <Form.Control
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        borderBottomWidth: "70%",
                      }}
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>

                  <Form.Group className="mb-5">
                    {touched.password && errors.password ? (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    ) : (
                      ""
                    )}
                    <Form.Control
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        borderBottomWidth: "80%",
                      }}
                      type={show ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group style={{ display: "flex" }}>
                    <Form.Check
                      type="checkbox"
                      onClick={() => setShow(!show)}
                      label="Show Password"
                    />
                  </Form.Group>
                      
                  <Button
                    className="btn"
                    style={{
                      width: "fit-content",
                      backgroundColor: "#1f3d50",
                      border: "none",
                      color: "white",
                      cursor: "pointer",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                      fontSize: "0.8rem",
                      marginTop: "20px"
                    }}
                    variant="primary"
                    type="submit"
                  >
                    Login
                  </Button>
                </Form>
                <div className="text-center mt-3" style={{paddingTop:"20px"}}>
                <Link to={"/forgot-password"}>ForgotPassword</Link>
                </div>
                
                <div className="text-center text-muted" style={{margin:"50px 20px 20px 20px"}}>
                  <p>Only Admin can Access to Login</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
