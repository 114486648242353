import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import "./App.css";
import LandingPage from "./components/langingPage/LandingPage";
import AboutUs from "./components/navbarItems/AboutUs";
import Explore from "./components/navbarItems/Explore";
import SocialMedia from "./components/navbarItems/SocialMedia";
import FAQ from "./components/navbarItems/FAQ";
import Login from "./components/login/Login";
import Cart from "./components/cart/Cart";
import NoPage from "./components/NoPage";
import Fish from "./components/collections/Fish";
import Birds from "./components/collections/Birds";
import Cat from "./components/collections/Cats";
import Dog from "./components/collections/Dogs";
import Hamster from "./components/collections/Hamster";
import Poultry from "./components/collections/Poultry";
import Rabbit from "./components/collections/Rabbit";
import AdminLogin from "./components/admin/AdminLogin";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminForgotPassword from "./components/admin/AdminForgotPassword";
import ResetPassword from "./components/admin/ResetPassword";
import AddProducts from "./components/admin/AddProducts";
import EditProduct from "./components/admin/EditProduct";
import Pigeon from "./components/collections/Pigeon";
import OrderSummery from "./components/user/OrderSummary";
import PaymentGateway from "./components/cart/PaymentGateway";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import OrderData from "./components/admin/OrderData";
import UserData from "./components/admin/UserData";
import Preloader from "./components/PreLoader";
import Policy from "./components/footer/policy";
import UserOrder from "./components/admin/UserOrder";
import { Analytics } from "@vercel/analytics/react";
import EditProfile from "./components/user/EditProfile";
// import NavbarComponent from './components/navbar/Navbar';
import { Helmet } from "react-helmet";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleRouteChange = () => {
    setLoading(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" name="description" />
        <title>
          Shop Premium Pet Products & Accessories at Salluuu Pets Affordably
        </title>
        <link rel="canonical" href="http://salluuupets.online" />
      </Helmet>
      {loading && <Preloader />}
      <Router>
        <ScrollToTop />
        <ScrollToTopButton />
        <Routes>
          <Route
            path="/"
            element={<LandingPage onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/explore"
            element={<Explore onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/aboutus"
            element={<AboutUs onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/socialmedia"
            element={<SocialMedia onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/faq"
            element={<FAQ onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/login"
            element={<Login onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/cart"
            element={<Cart onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/fish"
            element={<Fish onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/birds"
            element={<Birds onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/cat"
            element={<Cat onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/dog"
            element={<Dog onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/hamster"
            element={<Hamster onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/pigeon"
            element={<Pigeon onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/poultry"
            element={<Poultry onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/rabbit"
            element={<Rabbit onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/policy"
            element={<Policy onLoad={() => setLoading(false)} />}
          />

          <Route
            path="/orderSummary/:id"
            element={<OrderSummery onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/editprofile/:id"
            element={<EditProfile onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/payment-gateway/:id"
            element={<PaymentGateway onLoad={() => setLoading(false)} />}
          />

          <Route
            path="/admin"
            element={<AdminLogin onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/forgot-password"
            element={<AdminForgotPassword onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/reset-password/:id"
            element={<ResetPassword onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/dashboard/:id"
            element={<AdminDashboard onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/addproduct/:id"
            element={<AddProducts onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/editproduct/:id"
            element={<EditProduct onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/orderdata/:id"
            element={<OrderData onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/userdata/:id"
            element={<UserData onLoad={() => setLoading(false)} />}
          />
          <Route
            path="/admin/userorders/:id"
            element={<UserOrder onLoad={() => setLoading(false)} />}
          />

          <Route
            path="/*"
            element={<NoPage onLoad={() => setLoading(false)} />}
          />
        </Routes>
        <Analytics />
      </Router>
    </div>
  );
}

export default App;
