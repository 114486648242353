import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import logo1 from "../../assests/logo2.jpg";
import whatsapp from "../../assests/whatsapp.png";
import instagram from "../../assests/instagram.png";
import facebook from "../../assests/facebook.png";
import youtube from "../../assests/youtube.png";
import gamil from "../../assests/gmail.png";
import web from "../../assests/web.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const SocialMedia = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <Helmet>
        <title>Cute Pet Photos, Care Tips & Stories | Salluuu Pets</title>
        <meta
          name="description"
          content="Join Salluuu Pets for daily cute pet photos, expert care tips, heartwarming stories, and special offers. Follow us on social media for the latest pet updates and product highlights."
        />
      </Helmet>

      <NavbarComponent totalQuantityInCart={totalQuantityInCart} />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="heading">
          <img src={logo1} alt="Fish" className="" style={{ height: "80px" }} />
          <h1>Salluuu Pets</h1>
        </div>
        <div className="link-group chakra-petch-light">
          <h2>Social Media</h2>
        </div>
        <div className="link-group chakra-petch-light">
          <a
            href="https://wa.me/+918438270183"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={whatsapp} alt="Whatsapp" className="link-image" />
            <div className="text-1 ">Whatsapp</div>
          </a>
          <a
            href="https://www.instagram.com/salluuu_farming?igsh=MTd1eG43cnZzZnV4Mw%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={instagram} alt="Instagram" className="link-image" />
            <div className="text-1">Instagram</div>
          </a>
          <a
            href="https://m.facebook.com/profile.php?id=100067189053375&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={facebook} alt="Facebook" className="link-image" />
            <div className="text-1">Facebook</div>
          </a>
          <a
            href="https://youtube.com/@salluuu_farming?si=R28ZJTveyxL8JIFg"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={youtube} alt="Youtube" className="link-image" />
            <div className="text-1">Youtube</div>
          </a>
          <a
            href="https://portfolio1-mohamed-salman-faris.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={web} alt="Website" className="link-image" />
            <div className="text-1">Website</div>
          </a>
          <a
            href="mailto:md.salmanfaris03@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link-box"
          >
            <img src={gamil} alt="mail" className="link-image" />
            <div className="text-1">Mail</div>
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SocialMedia;
