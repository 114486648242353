import React from "react";
import fish from "../../assests/fish2.jpg";
import bird from "../../assests/birds2.webp";
import cat from "../../assests/cat1.jpg";
import dog from "../../assests/dog1.JPG";
import hamster from "../../assests/hamster2.jpg";
import pegion from "../../assests/pegion.jpg";
import rabbit from "../../assests/rabbit.webp";
import poultry from "../../assests/poultry.jpg";
import { Link } from "react-router-dom";

const Collections = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="heading">
          <h1>Shop By Collections</h1>
        </div>
        <div className="collections chakra-petch-light">
          <Link to="/fish" className="image-container">
            <img src={fish} alt="Fish" className="image" />
            <div className="text ">Fish</div>
          </Link>
          <Link to="/birds" className="image-container">
            <img src={bird} alt="Birds" className="image" />
            <div className="text">Birds</div>
          </Link>
          <Link to="/cat" className="image-container">
            <img src={cat} alt="Cat" className="image" />
            <div className="text">Cat</div>
          </Link>
          <Link to="/dog" className="image-container">
            <img src={dog} alt="Dog" className="image" />
            <div className="text">Dog</div>
          </Link>
          <Link to="/hamster" className="image-container">
            <img src={hamster} alt="Hamster" className="image" />
            <div className="text">Hamster</div>
          </Link>
          <Link to="/pigeon" className="image-container">
            <img src={pegion} alt="Pigeon" className="image" />
            <div className="text">Pigeon</div>
          </Link>
          <Link to="/rabbit" className="image-container">
            <img src={rabbit} alt="Rabbit" className="image" />
            <div className="text">Rabbit</div>
          </Link>
          <Link to="/poultry" className="image-container">
            <img src={poultry} alt="Poultry" className="image" />
            <div className="text">Poultry</div>
          </Link>
        </div>
        <div
          className="container-fluid"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Link to="/explore" style={{ textDecoration: "none" }}>
            <h1
              className="heading"
              style={{
                color: "#333",
                fontSize: "24px",
                fontWeight: "bold",
                margin: "0",
              }}
            >
              Show All Products
            </h1>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Collections;
