import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import NavbarComponent from "../navbar/Navbar";
import CarouselComponent from "../imageSlider/ImageSlider";
import TextSlider from "../imageSlider/TextSlider";
import Collections from "../collections/Collections";
import ProductCard from "../card/Card";
import Footer from "../footer/Footer";
import axios from "axios";
import API from "../../api/API";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/user/userSlice";

const LandingPage = () => {
  const [userData, setUserData] = useState({});
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const token = location.state?.token || localStorage.getItem("token");

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("User Data :", response);
      // toast.success(response.data.message);
      setUserData(response.data.user);
      console.log(response.data.user);
      dispatch(setUser(response.data.user));
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        // Token is invalid or expired
        localStorage.removeItem("token");
      } else {
        console.error("Failed to fetch user data:", error);
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, [token]);

  const texts = [
    "NO CASH ON DELIVERY",
    "We Do Not Accept Returns!",
    "10% Discount For Cat Products",
    "All India Delivery",
    "Secure Login System",
    "Purchase above ₹5000 delivery charge will be free"
  ];

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          // content="Discover top-quality pet products for fish, birds, dogs, and cats at Salluuu Pets. Shop premium food, toys, and grooming essentials with fast shipping and great service."
        />
        <title>
          Shop Premium Pet Products & Accessories at Salluuu Pets Affordably
        </title>

        <link rel="canonical" href="http://salluuupets.online" />
      </Helmet>
      <NavbarComponent
        token={token}
        userName={userData.userName}
        totalQuantityInCart={totalQuantityInCart}
      />
      <TextSlider texts={texts} interval={3000} style={{ marginTop: "90px" }} />
      <CarouselComponent />
      <Collections />

      <Footer />
    </>
  );
};

export default LandingPage;
