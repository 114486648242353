import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Alert,
  Toast,
} from "react-bootstrap";
import { storage } from "../Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import API from "../../api/API";
import { toast } from "react-toastify";
import AdminNavbar from "./AdminNavbar";
import { IoIosAlert } from "react-icons/io";
import { FaSmile } from "react-icons/fa";
import { MdBrowserUpdated } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";

const EditProduct = () => {
    const [productTitle, setProductTitle] = useState("");
    const [productContent, setProductContent] = useState("");
    const [mrpPrice, setMrpPrice] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const [quantity, setQuantity] = useState("");
  
    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [adminData, setAdminData] = useState({});
    const [productData, setProductData] = useState({});
  
    const [image, setImage] = useState(null);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
  
    const [unsavedChanges, setUnsavedChanges] = useState(false);
  
    const [productCategory, setProductCategory] = useState("");
    const [productSubCategory, setProductSubCategory] = useState("");
  
    const { pathname } = location;
    const productFullID = pathname.split("/")[2] || null;
    const _id = productFullID.split("-")[0];
    const productID = productFullID.split("-")[1];
    console.log(_id, productID);
  
    const token = location.state?.token || localStorage.getItem("token");
  
    const userName = adminData.userName;
    const userID = adminData._id;
    console.log("User ID", userID);
    console.log("User Name", userName);
  
    const { id } = useParams();
  
    const getAdminData = async () => {
      try {
        const response = await axios.get(`${API}/admin/getadmin`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAdminData(response.data.user);
      } catch (error) {
        console.log(error);
      }
    };
  
    const getProductData = async (_id) => {
      try {
        const response = await axios.get(`${API}/product/getproduct/${_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const product = response.data.product;
        setProductData(product);
        setProductTitle(product.productTitle || "");
        setProductContent(product.productContent || "");
        setMrpPrice(product.mrpPrice || "");
        setNewPrice(product.newPrice || "");
        setQuantity(product.quantity || "");
        setProductCategory(product.productCategory || "");
        setProductSubCategory(product.productSubCategory || "");
        setImageUrl(product.productImage || "");
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
  
    useEffect(() => {
      if (!token) {
        navigate("/admin");
      } else {
        getProductData(_id);
        getAdminData();
      }
    }, [token]);
  
    const handlePublish = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const productData = {
          productTitle,
          productContent,
          mrpPrice,
          newPrice,
          quantity,
          productCategory,
          productSubCategory,
          productImage: imageUrl,
          
        };
        console.log("productData:", productData);
    
        const response = await axios.put(
          `${API}/product/product-post-update/${_id}`,
          productData
        );
    
        toast.success("Post Updated Successfully!");
        navigate(`/dashboard/${adminData._id}`);
      } catch (error) {
        console.error("Failed to update post:", error);
        toast.error("Failed to update post.");
      } finally {
        setLoading(false);
      }
    };
    
  
    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
      }
    };
  
    const uploadImage = async () => {
      try {
        setUploading(true);
  
        if (image) {
          const storageRef = ref(
            storage,
            `productImages/${adminData._id}_${adminData.userName}_${image.name}`
          );
          await uploadBytes(storageRef, image);
          const downloadURL = await getDownloadURL(storageRef);
          setImageUrl(downloadURL);
          setImageUploaded(true);
          toast.success("Image uploaded successfully!");
        } else {
          toast.error("No image selected.");
        }
      } catch (error) {
        toast.error("Image upload failed");
      } finally {
        setUploading(false);
      }
    };
  
    const handleInputChange = () => {
      setUnsavedChanges(true);
    };
  
    useEffect(() => {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [unsavedChanges]);
  
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
  
    const handleMrpPriceChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        setMrpPrice(value);
      }
    };
  
    const handleNewPriceChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setNewPrice(value);
      }
    };
  
    const handleQuantityChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        setQuantity(value);
      }
    };
  
    const handleCategoryChange = (e) => {
      setProductCategory(e.target.value);
      setProductSubCategory("");
    };

  return (
    <>
      <AdminNavbar adminData={adminData} />
      <section className="h-100 gradient-custom-2 section mt-4 pt-5">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div className="card" style={{ padding: "7px" }}>
                <h1 className="heading">Edit Product</h1>

                <Form className="container-lg  product_form">
                  <div className="product-form">
                    <Form.Group
                      controlId="formProductTitle"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Title*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Edit Product Title"
                        name="productTitle"
                        // value={productTitle}
                        defaultValue={productData.productTitle}
                        onChange={(e) => setProductTitle(e.target.value)}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductContent"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Description*</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        placeholder="Write Your Product Description..."
                        name="productContent"
                        // value={productContent}
                        defaultValue={productData.productContent}
                        onChange={(e) => setProductContent(e.target.value)}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="product-form"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Group
                      controlId="productMrpPrice"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>MRP Price*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="150"
                        name="mrpPrice"
                        // value={mrpPrice}
                        defaultValue={productData.mrpPrice}
                        onChange={handleMrpPriceChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="productNewPrice"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>New Price*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="100"
                        name="newPrice"
                        // value={newPrice}
                        defaultValue={productData.newPrice}
                        onChange={handleNewPriceChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductContent"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Quantity*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="100"
                        name="quantity"
                        // value={quantity}
                        defaultValue={productData.quantity}
                        onChange={handleQuantityChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductCategory"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Category*</Form.Label>
                      <Form.Select
                        onChange={(e) => setProductCategory(e.target.value)}
                        value={productCategory}
                        style={{ border: " 1px solid #2e6ca4" }}
                      >
                        <option value="">Select Category</option>
                        <option value="fish">Fish</option>
                        <option value="birds">Birds</option>
                        <option value="cat">Cat</option>
                        <option value="dog">Dog</option>
                        <option value="hamster">Hamster</option>
                        <option value="pigeon">Pigeon</option>
                        <option value="rabbit">Rabbit</option>
                        <option value="poultry">Poultry</option>
                      </Form.Select>
                    </Form.Group>
                  </div>

                  {productCategory === "fish" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="fish food">Fish Food</option>
                          <option value="fish tank">Fish Tank</option>
                          <option value="fish medicine">Fish Medicine</option>
                          <option value="fish tank accessories">
                            Fish Tank Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "birds" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubcategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="birds food">Birds Food</option>
                          <option value="birds cage">Birds Cage</option>
                          <option value="birds medicine">Birds Medicine</option>
                          <option value="birds cage accessories">
                            Birds Cage Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "cat" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="cat food">Cat Food</option>
                          <option value="cat toys">Cat Toys</option>
                          <option value="cat medicine">Cat Medicine</option>
                          <option value="cat accessories">
                            Cat Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "dog" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="dog food">Dog Food</option>
                          <option value="dog toys">Dog Toys</option>
                          <option value="dog medicine">Dog Medicine</option>
                          <option value="dog accessories">
                            Dog Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "hamster" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="hamster food">Hamster Food</option>
                          <option value="hamster cage">Hamster Cage</option>
                          <option value="hamster medicine">
                            Hamster Medicine
                          </option>
                          <option value="hamster accessories">
                            Hamster Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "pigeon" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="pigeon food">Pigeon Food</option>
                          <option value="pigeon medicine">
                            Pigeon Medicine
                          </option>
                          {/* <option value="pigeon cage">Pigeon Cage</option> */}
                          <option value="pigeon accessories">
                            Pigeon Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "rabbit" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="rabbit food">Rabbit Food</option>
                          <option value="rabbit cage">Rabbit Cage</option>
                          <option value="rabbit medicine">
                            Rabbit Medicine
                          </option>
                          <option value="rabbit accessories">
                            Rabbit Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "poultry" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubcategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="poultry food">Poultry Food</option>
                          <option value="poultry medicine">
                            Poultry Medicine
                          </option>
                          <option value="poultry accessories">
                            Poultry Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}

                  <div className="product-form">
                    <Form.Group
                      controlId="formImageUpload"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Image*</Form.Label>
                      <InputGroup className="mb-3">
                        {/* Display image inside the input field */}

                        <FormControl
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ border: "1px solid #2e6ca4" }}
                          defaultValue={productData.productImage}
                        />
                        {!imageUploaded && (
                          <InputGroup.Text className="icon">
                            <img
                              src={productData.productImage}
                              alt="Default Icon"
                              className="default-icon"
                              style={{
                                width: "30px",
                                overflow: "hidden",
                                height: "30px",
                                objectFit: "",
                              }}
                            />
                          </InputGroup.Text>
                        )}
                        <InputGroup.Text
                          className="icon"
                          style={{
                            cursor: "pointer",
                            border: "1px solid #2e6ca4",
                          }}
                          onClick={!isUploading ? uploadImage : null}
                          disabled={isUploading}
                        >
                          {isUploading ? (
                            <AiOutlineLoading3Quarters className="rotating-icon" />
                          ) : (
                            <MdBrowserUpdated
                              style={{ fontSize: "25px", cursor: "pointer" }}
                              onClick={!isUploading ? uploadImage : null}
                            />
                          )}
                          {isUploading ? "Uploading..." : "Upload"}
                        </InputGroup.Text>
                      </InputGroup>
                      {/* Additional UI for showing success message */}
                      {imageUploaded && (
                        <div className="alert-container">
                          <p className="alert-msg" style={{ color: "green" , padding:"0", margin:"0"}}>
                            Image Uploaded Successfully
                          </p>
                          <FaSmile
                            style={{ color: "green", marginLeft: "5px" }}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <Button
                    variant="primary"
                    className="button"
                    style={{ margin: "20px 0px 20px 0px" }}
                    type="submit"
                    onClick={!isLoading ? handlePublish : null}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>

                  {/* <Button
                    variant="primary"
                    className="button"
                    style={{ margin: "20px 0px 20px 0px" }}
                    type="submit"
                    onClick={!isLoading ? handlePublish : null}
                    disabled={
                      isLoading ||
                      !productTitle ||
                      !productContent ||
                      !mrpPrice ||
                      !newPrice ||
                      !quantity ||
                      !productCategory ||
                      !productSubCategory ||
                      !imageUploaded
                    }
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Publishing...
                      </>
                    ) : (
                      "Publish"
                    )}
                  </Button> */}

                  {unsavedChanges && (
                    <div className="mt-3">
                      <Alert variant="warning">
                        <IoIosAlert /> You have unsaved changes.
                      </Alert>
                    </div>
                  )}
                </Form>

                <div className="text-center mt-3">
                  <Link
                    style={{ fontSize: "15px", textDecoration: "underline" }}
                    to={`/dashboard/${adminData._id}`}
                  >
                    <RiArrowGoBackFill style={{ fontSize: "20px" }} /> Back to
                    Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditProduct;
