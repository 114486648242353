import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <>
          <button onClick={scrollToTop} style={styles.button}>
            <FaArrowUp style={styles.icon} />
          </button>
          {/* <p style={{color:"#1679AB"}}>Move to Top</p> */}
        </>
      )}
    </div>
  );
}

const styles = {
  button: {
    position: "fixed",
    bottom: "4%",
    right: "4%",
    width: "50px",  // Increased width for better appearance
    height: "50px",  // Increased height for better appearance
    borderRadius: "50%",
    backgroundColor: "#1679AB",
    color: "#fff",
    border: "none",
    outline: "none",
    cursor: "pointer",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
    transition: "opacity 0.3s ease-in-out",
    display: "flex",  // Use flexbox to center the icon
    justifyContent: "center",  // Center the icon horizontally
    alignItems: "center",  // Center the icon vertically
    zIndex: 1000,  // Ensures the button appears on top of other elements
  },
  icon: {
    fontSize: "24px",  // Adjusted font size for better appearance
  },
};

export default ScrollToTopButton;
