import React, { useState, useEffect, useRef } from "react";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import API from "../../api/API";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setUser, selectUser } from '../../features/user/userSlice';

const EditProfile = () => {
  const [cart, setCart] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [openQuestion, setOpenQuestion] = useState(null);
  const [instruction, setInstruction] = useState("")
  const [fullAddress, setFullAddress] = useState({
    userName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const token = location.state?.token || localStorage.getItem("token");

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const user = response.data.user;
      setUserData(user);
      setUserName(user.userName);
      setPhoneNumber(user.phoneNumber);
      setEmail(user.email);
      setFullAddress({
        userName: user.userName || "",
        email: user.email || "",
        address: user.address?.address || "",
        city: user.address?.city || "",
        state: user.address?.state || "",
        zip: user.address?.zip || "",
      });
      setInstruction(user.instruction);
    } catch (error) {
      console.log(error);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // Token is invalid or expired
        localStorage.removeItem("token");
        
      } else {
        console.error("Failed to fetch user data:", error);
      }
    }
  };

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
    getUserData();
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleUpdateProfile = async () => {
    if (!isValidEmail) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      const updateData = {
        userId: userData._id, // Use _id instead of userId
        userName: userName,
        email: email,
        phoneNumber: phoneNumber,
      };
      console.log("UpdateData:", updateData);
      const response = await axios.put(`${API}/users/updateuser`, updateData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
      toast.success("Profile updated successfully!");
      getUserData();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const toggleQuestion = (questionIndex) => {
    setOpenQuestion(openQuestion === questionIndex ? null : questionIndex);
  };

  const statesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  useEffect(() => {
    if (userData.userName && userData.email) {
      setFullAddress((prevAddress) => ({
        ...prevAddress,
        name: userData.userName || "",
        email: userData.email || "",
        address: userData.address?.address || "",
        city: userData.address?.city || "",
        state: userData.address?.state || "",
        zip: userData.address?.zip || "",
      }));
    }
  }, [userData]);

  const addressInputRef = useRef(null);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFullAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleZipChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      // Only allow digits
      handleAddressChange(event);
    }
  };

  const handleAddressUpdate = async () => {
    setLoading(true);
    // console.log("FullAddress", fullAddress);
    try {
      const response = await axios.put(
        `${API}/users/updateAddress`,
        {address: fullAddress},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUserData(response.data.user);
      toast.success("Profile updated successfully!");
      getUserData();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update Address. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateInstruction = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${API}/users/updateInstruction`,
        {instruction: instruction},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUserData(response.data.user);
      setInstruction(response.data.user.instruction)
      toast.success("Instruction updated successfully!");
      getUserData();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update Instruction. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <NavbarComponent
        totalQuantityInCart={totalQuantityInCart}
        token={token}
        userName={userData.userName}
      />
      <section
        className="h-100 gradient-custom-2 section"
        style={{ marginTop: "75px" }}
      >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div
                className="card"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                }}
              >
                <div className="order-container">
                  <div className="container-fluid faq-container">
                    <h1 className="faq-title">Update Profile</h1>
                    <div className="faq-item">
                      <div
                        className="faq-question-container"
                        onClick={() => toggleQuestion(1)}
                      >
                        <h3 className="faq-question">
                          Update Personal Details
                        </h3>
                        <span className="faq-toggle-icon">
                          {openQuestion === 1 ? "-" : "+"}
                        </span>
                      </div>
                      {openQuestion === 1 && (
                        <Form className="container-lg my-4" overflow-auto>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              Mobile Number:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={phoneNumber}
                              defaultValue={phoneNumber}
                              disabled
                              placeholder="Enter your Mobile Number"
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                            />
                          </Form.Group>

                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={userName}
                              defaultValue={userName}
                              onChange={(e) => setUserName(e.target.value)}
                              placeholder="Enter your name"
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={!userName}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Please provide your name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              Email:
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={email}
                              defaultValue={email}
                              onChange={handleEmailChange}
                              placeholder="Enter your email"
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={
                                !email ||
                                !/\S+@\S+\.\S+/.test(email)
                              }
                            />

                            {(!email ||
                              !/\S+@\S+\.\S+/.test(email)) && (
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid email address.
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <p
                            className="text-muted"
                            style={{
                              margin: "25px 0px 0px 0px",
                              fontSize: "15px",
                            }}
                          >
                            Yes, I want to receive important information &
                            updates on my E-Mail
                          </p>
                          <Button
                            variant="primary"
                            className="mt-3"
                            style={{
                              backgroundColor: "#0A66C2",
                              borderRadius: "50px",
                              width: "100%",
                              fontSize: "1.2em",
                            }}
                            type="button"
                            onClick={handleUpdateProfile}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <div
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                style={{ fontSize: "18px" }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : null}
                            {isLoading ? "Updating..." : "Update Profile"}
                          </Button>
                        </Form>
                      )}
                    </div>

                    <div className="faq-item">
                      <div
                        className="faq-question-container"
                        onClick={() => toggleQuestion(2)}
                      >
                        <h3 className="faq-question">
                          Update Delivery Address
                        </h3>
                        <span className="faq-toggle-icon">
                          {openQuestion === 2 ? "-" : "+"}
                        </span>
                      </div>
                      {openQuestion === 2 && (
                        <Form>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              Address:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="address"
                              value={fullAddress.address}
                              onChange={handleAddressChange}
                              placeholder="Enter Door No & Street Name"
                              ref={addressInputRef}
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={!fullAddress.address}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide your address.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              City:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="city"
                              value={fullAddress.city}
                              onChange={handleAddressChange}
                              placeholder="Enter city"
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={!fullAddress.city}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide your city.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              State:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="state"
                              value={fullAddress.state}
                              onChange={handleAddressChange}
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={!fullAddress.state}
                            >
                              <option value="">Select state</option>
                              {statesOfIndia.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide your state.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              ZIP Code:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="zip"
                              value={fullAddress.zip}
                              onChange={handleZipChange}
                              placeholder="Enter ZIP code"
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              maxLength="6"
                              pattern="\d{6}"
                              isInvalid={
                                !fullAddress.zip ||
                                !/^\d{6}$/.test(fullAddress.zip)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid ZIP code.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            variant="primary"
                            className="mt-3"
                            onClick={handleAddressUpdate}
                            style={{
                              backgroundColor: "#0A66C2",
                              borderRadius: "50px",
                              width: "100%",
                              fontSize: "1.2em",
                            }}
                            disabled={
                              !fullAddress.address ||
                              !fullAddress.city ||
                              !fullAddress.state ||
                              !fullAddress.zip ||
                              isLoading
                            }
                          >
                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                <span className="ms-2">Updating...</span>
                              </>
                            ) : (
                              <>Update Address</>
                            )}
                          </Button>
                        </Form>
                      )}
                    </div>

                    <div className="faq-item" >
                      <div className="faq-question-container" onClick={() => toggleQuestion(3)}>
                        <h3 className="faq-question">
                          Update Delivery Instructions 
                        </h3>
                        <span className="faq-toggle-icon">
                          {openQuestion === 3 ? "-" : "+"}
                        </span>
                      </div>
                      {openQuestion === 3 && (
                        <Form>
                          <Form.Group
                            style={{
                              textAlign: "left",
                              alignContent: "left",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                color: "#0A66C2",
                                marginTop: "1rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              Delivery Instructions:
                            </Form.Label>
                            <Form.Control
                              as="textarea" 
                              rows={4} 
                              name="deliveryInstructions"
                              placeholder="Enter Delivery Instructions"
                              value={instruction}
                              defaultValue={instruction}
                              onChange={(e) => setInstruction(e.target.value)}
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #007bff",
                                marginBottom: "15px",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              isInvalid={!instruction}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Please provide Delivery Instructions.
                              </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            variant="primary"
                            className="mt-3"
                            style={{
                              backgroundColor: "#0A66C2",
                              borderRadius: "50px",
                              width: "100%",
                              fontSize: "1.2em",
                            }}
                            type="button"
                            onClick={handleUpdateInstruction}
                            disabled={isLoading || !instruction}
                          >
                            {isLoading ? (
                              <div
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                style={{ fontSize: "18px" }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : null}
                            {isLoading ? "Updating..." : "Update Instructions"}
                          </Button>
                        </Form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EditProfile;
