// src/features/user/userSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  user: {
    userName: '',
    phoneNumber: '',
    email: '',
    address: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
      },
    instruction:'',

  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    // Additional reducers can be added here, for example:
    updateUserName: (state, action) => {
        state.user.userName = action.payload;
      },
      updateUserEmail: (state, action) => {
        state.user.email = action.payload;
      },
  },
});

export const { setUser, updateUserName, updateUserEmail } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;