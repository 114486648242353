// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { Button } from 'react-bootstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
// import API from "../../api/API";

// const PaymentGateway = ({ orderData, country }) => {
//   const [cart, setCart] = useState([]);
//   const [productData, setProductData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [orderSummary, setOrderSummary] = useState([]);
//   const [orderItemSummary, setOrderItemSummary] = useState([]);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [userData, setUserData] = useState({});
//   const token = location.state?.token || localStorage.getItem("token");

//   const getUserData = async () => {
//     try {
//       const response = await axios.get(`${API}/users/getuser`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setUserData(response.data.user);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getProductData = async () => {
//     try {
//       const response = await axios.get(`${API}/product/showproducts`);
//       const products = {};
//       response.data.products.forEach((product) => {
//         products[product._id] = product;
//       });
//       setProductData(products);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching product data:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     const savedCart = localStorage.getItem("cart");
//     if (savedCart) {
//       setCart(JSON.parse(savedCart));
//     }
//     getUserData();
//     getProductData();
//   }, []);

//   const totalQuantityInCart = cart.reduce(
//     (total, item) => total + item.quantity,
//     0
//   );

//   const getOrderSummary = async () => {
//     try {
//       const response = await axios.get(`${API}/users/orders`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setOrderSummary(response.data.orders);
//     } catch (error) {
//       console.error("Error fetching order summary:", error);
//     }
//   };
//   const getOrderItemsSummary = async () => {
//     try {
//       const response = await axios.get(`${API}/users/orderItems`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setOrderItemSummary(response.data.orderItems);
//       // console.log(response.data.orderItems);
//       // console.log(orderItemSummary);
//     } catch (error) {
//       console.error("Error fetching order summary:", error);
//     }
//   };

//   useEffect(() => {
//     getOrderSummary();
//     getOrderItemsSummary();
//   }, [token]);
  

//   const handlePayment = async () => {
//     try {
//       setLoading(true);
//       // Call your server to create a payment order
//       const response = await axios.post('/api/create-payment', {
//         amount: orderData.total, // Amount in your application's currency
//         currency: country === 'India' ? 'INR' : 'USD', // Currency code
//       });
      
//       const { orderId, amount, currency } = response.data;

//       const options = {
//         key: process.env.KEY_ID, // Your Razorpay Key ID
//         amount: amount * 100, // Amount in paisa
//         currency: currency, // Currency code
//         name: 'Your Company Name',
//         description: 'Payment for Order #123',
//         order_id: orderId, // Order ID generated from server
//         handler: async function(response) {
//           // Handle successful payment
//           const paymentId = response.razorpay_payment_id;
//           // Call your server to verify payment
//           const verifyResponse = await axios.post(`${API}/users/verify-payment`, {
//             paymentId: paymentId,
//             orderId: orderId,
//           });
//           // Display success message
//           toast.success('Payment successful!');
//           console.log(verifyResponse.data);
//         },
//         prefill: {
//           name: 'John Doe', // Customer name
//           email: 'john@example.com', // Customer email
//           contact: '+919876543210', // Customer contact number
//         },
//         notes: {
//           address: '123, ABC Street', // Additional notes if any
//         },
//         theme: {
//           color: '#3399cc', // Razorpay checkout theme color
//         },
//       };
//       const rzp = new window.Razorpay(options);
//       rzp.open();
//     } catch (error) {
//       toast.error('Failed to initiate payment. Please try again later.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // useEffect(() => {
//   //   handlePayment();
//   // }, []);

//   return (
//     <div>
//       <Button onClick={handlePayment} disabled={loading}>
//         {loading ? 'Processing...' : 'Proceed to Pay'}
//       </Button>
//       <ToastContainer />
//     </div>
//   );
// };

// export default PaymentGateway;


import React,{useState} from 'react';

function PaymentGateway () {
  const [amount, setamount] = useState('');

  const handleSubmit = (e)=>{
    e.preventDefault();
    if(amount === ""){
    alert("please enter amount");
    }else{
      var options = {
        key: "rzp_test_yVFC0dAfJqi4go", //process.env.KEY_ID,
        key_secret: "uoWIkG79ozIzQxctYOofgn19", //process.env.KEY_SECRET,
        amount: amount *100,
        currency:"INR",
        name:"STARTUP_PROJECTS",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Velmurugan",
          email:"mvel1620r@gmail.com",
          contact:"7904425033"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#3399cc"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }
  return (
    <div className="App">
     <h2>Razorpay Payment Integration Using React</h2>
     <br/>
     <input type="text"placeholder='Enter Amount'value={amount}onChange={(e)=>setamount(e.target.value)} />
     <br/><br/>
     <button onClick={handleSubmit}>submit</button>
    </div>
  );
}

export default PaymentGateway;