import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaUser } from "react-icons/fa";
import OtpInput from "otp-input-react";
import {
  MdOutlineEdit,
  MdPhoneIphone,
  MdOutlineAlternateEmail,
} from "react-icons/md";
import { auth } from "../Firebase.js";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { toast } from "react-toastify";
import API from "../../api/API.js";
import axios from "axios";
import { IoIosAlert } from "react-icons/io";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/user/userSlice";

const Login = () => {
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [otp, setOtp] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(120);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [cart, setCart] = useState([]);

  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(false);
  const [isVerifyOtpDisabled, setIsVerifyOtpDisabled] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const recaptchaVerifierRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    if (editClicked) {
      // Your code to run when the edit is clicked
      console.log("Edit Number clicked");
      // Reset the editClicked state if needed
      setEditClicked(false);
    }
  }, [editClicked]);

  useEffect(() => {
    // Initialize the RecaptchaVerifier instance
    const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "normal",
      callback: (response) => {
        // Handle reCAPTCHA verification success
      },
      "expired-callback": () => {
        // Handle reCAPTCHA expiration
      },
    });

    // Attach the RecaptchaVerifier instance to the window object for accessibility
    window.recaptchaVerifier = verifier;
    // verifier.clear();
    // Clean up function to be called when the component unmounts
    return () => {
      // Check if the verifier exists before clearing it
      if (verifier) {
        // Call the clear() method to close the reCAPTCHA widget
        verifier.clear();
      }
    };
  }, []);

  // CSS for centering the reCAPTCHA widget horizontally
  const recaptchaStyle = {
    display: show ? "none" : "flex",
    justifyContent: "center",
  };

  const handlePhoneNumberChange = (e) => {
    let inputValue = e.target.value;

    // Ensure the value is numeric and within the specified limit
    if (/^\d*$/.test(inputValue)) {
      // If the input exceeds 10 characters, trim it
      if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10);
      }
      setPhoneNumber(inputValue);
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    try {
      setLoading(true);
      setIsSendOtpDisabled(true);
      const formatPhoneNumber = "+91" + phoneNumber;
      console.log(formatPhoneNumber);
      const confirmation = await signInWithPhoneNumber(
        auth,
        formatPhoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully!");
      setShow(true);
    } catch (error) {
      toast.error(error.message);
      setIsSendOtpDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setResendDisabled(false);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleResendOTP = async () => {
    try {
      setLoading(true);
      setIsSendOtpDisabled(true);
      const formatPhoneNumber = "+91" + phoneNumber;
      console.log(formatPhoneNumber);
      const confirmation = await signInWithPhoneNumber(
        auth,
        formatPhoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully!");
      setShow(true);
      setResendDisabled(true);
      setTimer(120);
    } catch (error) {
      toast.error(error.message);
      setIsSendOtpDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const handleOTPSubmit = async () => {
    try {
      setLoading(true);
      setIsVerifyOtpDisabled(true);
      if (!confirmationResult) {
        throw new Error("Confirmation result not found.");
      }

      await confirmationResult.confirm(otp);

      const userData = {
        userName: userName,
        phoneNumber: phoneNumber,
        email: email,
      };
      console.log(userData);

      const response = await axios.post(`${API}/users/login-data`, userData);

      // console.log(response.data);
      const { message, user, token } = response.data; // Log
      // toast.success(message);
      toast.success("Login Successfully");
      localStorage.setItem("token", token);
      dispatch(setUser(user));
      // getUserData(token);
      navigate(-1);
    } catch (error) {
      toast.error("Failed to verify OTP. Please try again.");
      setIsVerifyOtpDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // const getUserData = async (token) => {
  //   try {
  //     const response = await axios.get(`${API}/users/getuser`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     // console.log("User Data :", response);
  //     // toast.success(response.data.message);
  //     // setUserData(response.data.user);
  //     console.log(response.data.user);
  //     dispatch(setUser(response.data.user));
  //   } catch (error) {
  //     console.log(error);
  //     if (
  //       error.response &&
  //       (error.response.status === 401 || error.response.status === 403)
  //     ) {
  //       // Token is invalid or expired
  //       localStorage.removeItem("token");
  //     } else {
  //       console.error("Failed to fetch user data:", error);
  //     }
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Salluuu Pets Login: Access Your Account & Track Orders</title>
        <meta
          name="description"
          content="Log in to your Salluuu Pets account to track orders, stay updated with the latest pet content, and manage your account effortlessly."
        />
      </Helmet>
      <NavbarComponent totalQuantityInCart={totalQuantityInCart} />
      <section
        className="h-100 gradient-custom-2 section"
        style={{ marginTop: "75px" }}
      >
        <div className="container py-5 h-100">
          <h5>Welcome to Salluuu Pets</h5>
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              {show ? (
                <>
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                    }}
                  >
                    <div>
                      <h6
                        style={{
                          textAlign: "left",
                          margin: "20px 0px 20px 10px",
                          fontSize: "20px",
                        }}
                      >
                        Welcome {userName}
                      </h6>
                      <h6
                        className="heading"
                        style={{
                          textAlign: "left",
                          margin: "10px 0px 10px 10px",
                          fontSize: "20px",
                        }}
                      >
                        OTP Send to
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between" style={{}}>
                      <h4
                        className="heading"
                        style={{
                          textAlign: "left",
                          margin: "10px 0px 10px 10px",
                          fontSize: "28px",
                        }}
                      >
                        {phoneNumber}
                      </h4>
                      <p
                        style={{
                          alignContent: "center",
                          margin: "10px 10px 10px 0px",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShow(false);
                          setIsSendOtpDisabled(false);
                          setEditClicked(true); // Enable the Get OTP button when edit is clicked
                        }}
                      >
                        <MdOutlineEdit /> Edit Number
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{ recaptchaStyle }}
                        id="recaptcha-container"
                      ></div>
                      <div>
                        <label
                          htmlFor="otp"
                          style={{ margin: "15px", fontSize: "24px" }}
                        >
                          Enter your OTP
                        </label>
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          className="opt-container"
                        ></OtpInput>
                        <button
                          onClick={handleOTPSubmit}
                          className="btn btn-primary"
                          style={{ margin: "15px", padding: "0.7rem" }}
                          disabled={isVerifyOtpDisabled} // Disable based on state
                        >
                          {isLoading ? (
                            <>
                              <Spinner animation="border" size="sm" />
                              <span className="ms-2">Verifing...</span>
                            </>
                          ) : (
                            <>Verify OTP</>
                          )}
                        </button>
                      </div>
                    </div>
                    {/* Resend OTP button */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        onClick={handleResendOTP}
                        className="btn btn-secondary"
                        style={{ margin: "15px", padding: "0.7rem" }}
                        disabled={resendDisabled || isSendOtpDisabled}
                      >
                        Resend OTP {resendDisabled ? `(${timer} seconds)` : ""}
                      </button>
                    </div>

                    {/* <div
                      style={{ recaptchaStyle }}
                      id="recaptcha-container"
                    ></div> */}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                    }}
                  >
                    <h1 className="heading" style={{ marginTop: "10px" }}>
                      Login
                    </h1>
                    <Form className="container-lg my-4" overflow-auto>
                      <div
                        className="user-form"
                        style={{ textAlign: "left", color: "#00215E" }}
                      >
                        <Form.Label style={{ color: "#00215E" }}>
                          User Name*
                        </Form.Label>
                        <InputGroup
                          className="mb-3"
                          style={{ color: "#00215E", borderColor: "#00215E" }}
                        >
                          <InputGroup.Text
                            className="icon"
                            style={{ color: "#00215E", borderColor: "#00215E" }}
                          >
                            <FaUser />
                          </InputGroup.Text>
                          <FormControl
                            className="form-control"
                            style={{ color: "#00215E", borderColor: "#00215E" }}
                            type="text"
                            placeholder="Enter your Name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      {/* <div
                        className="login-form"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          justifyItems: "center",
                        }}
                      >
                        <Form.Group
                          controlId="login-form"
                          style={{
                            textAlign: "left",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            justifyItems: "center",
                          }}
                        >
                          <Form.Label>Mobile Number*</Form.Label>

                          <PhoneInput
                            country={"in"}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            style={{ width: "100%" }}
                          />
                        </Form.Group>
                      </div> */}

                      <div
                        className="user-form"
                        style={{ textAlign: "left", color: "#00215E" }}
                      >
                        <Form.Label style={{ color: "#00215E" }}>
                          Mobile Number*
                        </Form.Label>
                        <InputGroup
                          className="mb-3"
                          style={{ color: "#00215E", borderColor: "#00215E" }}
                        >
                          <InputGroup.Text
                            className="icon"
                            style={{ color: "#00215E", borderColor: "#00215E" }}
                          >
                            <MdPhoneIphone
                              style={{
                                color: "#00215E",
                                borderColor: "#00215E",
                              }}
                            />
                          </InputGroup.Text>
                          <InputGroup.Text
                            className="icon"
                            style={{ color: "#00215E", borderColor: "#00215E" }}
                          >
                            +91
                          </InputGroup.Text>
                          <FormControl
                            className={`form-control ${
                              isValidPhoneNumber ? "" : "is-invalid"
                            }`}
                            style={{ color: "#00215E", borderColor: "#00215E" }}
                            type="text" // Change type from "number" to "text"
                            placeholder="Enter your Mobile Number"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                          />
                        </InputGroup>
                        {!isValidPhoneNumber && (
                          <div className="invalid-feedback">
                            Please enter a valid 10-digit mobile number.
                          </div>
                        )}
                      </div>

                      <div
                        className="user-form"
                        style={{ textAlign: "left", color: "#00215E" }}
                      >
                        <Form.Label style={{ color: "#00215E" }}>
                          E-Mail{" "}
                          <span style={{ color: "#5A72A0", fontSize: "13px" }}>
                            (Optional)
                          </span>
                        </Form.Label>
                        <InputGroup
                          className="mb-3"
                          style={{
                            color: "#00215E",
                            borderColor: isValidEmail ? "#00215E" : "red",
                          }}
                        >
                          <InputGroup.Text
                            className="icon"
                            style={{
                              color: "#00215E",
                              borderColor: isValidEmail ? "#00215E" : "red",
                            }}
                          >
                            <MdOutlineAlternateEmail
                              style={{
                                color: "#00215E",
                                borderColor: isValidEmail ? "#00215E" : "red",
                              }}
                            />{" "}
                          </InputGroup.Text>
                          <FormControl
                            style={{
                              color: "#00215E",
                              borderColor: isValidEmail ? "#00215E" : "red",
                            }}
                            className="form-control inputEmail"
                            type="email"
                            placeholder="Enter Your Email ID"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </InputGroup>
                        {!isValidEmail && (
                          <div className="alert-container1">
                            <IoIosAlert className="alert-icon1" />
                            <p className="alert-msg1" style={{ color: "red" }}>
                              Invalid email address
                            </p>
                          </div>
                        )}
                      </div>
                      <p
                        className="text-muted"
                        style={{
                          margin: "10px 0px",
                          fontSize: "15px",
                        }}
                      ></p>

                      <p
                        className="text-muted"
                        style={{
                          margin: "10px 0px",
                          fontSize: "15px",
                        }}
                      >
                        Yes, I want to receive important information & updates
                        on my WhatsApp/SMS
                      </p>
                      <Button
                        variant="primary"
                        className="button"
                        style={{ width: "100%" }}
                        type="button"
                        onClick={handleSignup}
                        disabled={
                          !isValidPhoneNumber ||
                          isLoading ||
                          phoneNumber.length !== 10 ||
                          !userName ||
                          isSendOtpDisabled
                        }
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            style={{ fontSize: "18px" }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : null}
                        {isLoading ? "Sending OTP..." : "Get OTP"}
                      </Button>
                    </Form>
                    <div id="recaptcha-container" style={recaptchaStyle}></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Login;
