import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assests/logo001.jpg";
import { IoCloseSharp, IoSearchSharp } from "react-icons/io5";
import { FaUserLarge } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { RiMenu3Line } from "react-icons/ri";
import { RiMenuFold3Line2 } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { toast } from "react-toastify";
import axios from "axios";
import API from "../../api/API";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectUser, setUser } from "../../features/user/userSlice";


function NavbarComponent({ handleClearSearch, totalQuantityInCart }) {
  const user = useSelector(selectUser);
  // console.log(user);
  const [cart, setCart] = useState([]);
  const [userData, setUserData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = location.state?.token || localStorage.getItem("token");
  // console.log(token);

  // useEffect(() => {
  //   const savedCart = localStorage.getItem("cart");
  //   if (savedCart) {
  //     setCart(JSON.parse(savedCart));
  //   }
  // }, []);
  // const totalQuantityInCart = cart.reduce(
  //   (total, item) => total + item.quantity,
  //   0
  // );

  // Fetch user data
  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
      dispatch(setUser(response.data.user));
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        // Token is invalid or expired
        localStorage.removeItem("token");
      } else {
        console.error("Failed to fetch user data:", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      setShowOffcanvas(false);
      navigate(`/explore?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    navigate("/explore");
  };

  const handleLogout = () => {
    const shouldLogout = window.confirm(`Are you sure you want to Logout ?`);
    if (shouldLogout) {
      localStorage.clear();
      toast.success("Logged out Successful");
      navigate("/login");
      localStorage.removeItem("cart");
      // setCart([]);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary fixed-top"
          style={{ marginBottom: "90px" }}
        >
          <Container fluid>
            <Navbar.Brand>
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <img
                  src={logo}
                  width="50"
                  height="50"
                  className="d-inline-block align-top"
                  alt="Salluuu Pets logo"
                  style={{ marginLeft: "20px" }}
                />
                <span
                  style={{
                    fontSize: "30px",
                    fontFamily: "Macondo, cursive",
                    fontWeight: "500",
                    fontStyle: "normal",
                    marginLeft: "10px",
                    color: "#322C2B", //"inherit",
                  }}
                >
                  Salluuu Pets
                </span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setShowOffcanvas(true)}
              as="button"
              style={{ position: "relative" }}
            >
              <RiMenuFold3Line2
                style={{
                  color: "#00215E",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              />
              {/* Show totalQuantityInCart if it's greater than 0 */}
              {totalQuantityInCart > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    backgroundColor: "black",
                    color: "#fff",
                    borderRadius: "50%",
                    padding: "6px",
                    fontSize: "14px",
                    lineHeight: "14px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {totalQuantityInCart}
                </span>
              )}
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
            >
              <Offcanvas.Header closeButton>
                <img
                  src={logo}
                  width="50"
                  height="50"
                  className="d-inline-block align-top"
                  alt="Salluuu Pets logo"
                  to="/"
                />
                <Offcanvas.Title
                  to="/"
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  style={{ fontSize: "24px" }}
                >
                  Salluuu Pets
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-start flex-grow-1 pe-3 "
                  style={{ fontSize: "18px" }}
                >
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Home
                  </Link>
                  <Link
                    to="/explore"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Explore
                  </Link>
                  <Link
                    to="/aboutus"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/socialMedia"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Social Media
                  </Link>
                  <Link
                    to="/faq"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    FAQ
                  </Link>
                </Nav>
                <Form
                  className="d-flex"
                  onSubmit={handleSearchSubmit}
                  style={{ position: "relative" }}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search Product"
                    className="me-2"
                    aria-label="Search"
                    style={{
                      border: "none",
                      borderBottom: "2px solid #000",
                      borderRadius: "0px",
                      background: "none",
                      paddingRight: "30px", // Add padding to make space for the clear button
                    }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  {searchTerm && (
                    <IoCloseSharp
                      style={{
                        position: "absolute",
                        right: "67px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClearSearch();
                        handleClear();
                      }}
                    />
                  )}
                  <IoSearchSharp
                    style={{
                      fontSize: "34px",
                      margin: "auto",
                      justifyContent: "center",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleSearchSubmit}
                  />
                </Form>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {token ? (
                    <NavDropdown
                      title={
                        <span style={{ verticalAlign: "middle" }}>
                          <FaUserLarge style={{ marginRight: "5px" }} />{" "}
                          {user.userName}
                        </span>
                      }
                      id="basic-nav-dropdown"
                      style={{ fontSize: "20px", textAlign: "left" }}
                    >
                      <NavDropdown.Item style={{ fontSize: "18px" }}>
                        <Link
                          to={`/orderSummary/${user._id}`}
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <FaShoppingCart style={{ marginRight: "5px" }} />{" "}
                          Order Summary
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ fontSize: "18px" }}>
                        <Link
                          to={`/editprofile/${user._id}`}
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <TbEdit style={{ marginRight: "5px" }} /> Edit Profile
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        style={{ fontSize: "18px" }}
                        onClick={handleLogout}
                      >
                        <FaSignOutAlt style={{ marginRight: "5px" }} /> Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <Link
                      to="/login"
                      style={{ fontSize: "20px" }}
                      className="nav-link"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Login{" "}
                      <FaUserLarge
                        style={{ fontSize: "22px", marginLeft: "5px" }}
                      />
                    </Link>
                  )}

                  <Link
                    to="/cart"
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Cart{" "}
                    <FaShoppingCart
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    />
                    {totalQuantityInCart > 0 && (
                      <span
                        style={{
                          marginLeft: "4px",
                          color: "#fff",
                          backgroundColor: "black",
                          borderRadius: "50%",
                          padding: "6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "20px",
                          height: "20px",
                          fontSize: "14px",
                          lineHeight: "14px",
                        }}
                      >
                        {totalQuantityInCart}
                      </span>
                    )}
                  </Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavbarComponent;
