import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Spinner, FormControl, InputGroup } from "react-bootstrap";
import AdminNavbar from "./AdminNavbar";
import API from "../../api/API";
import { BiSolidShow } from "react-icons/bi";

const UserData = () => {
  const [adminData, setAdminData] = useState({});
  const [userData, setUserData] = useState([]);
  const [userOrders, setUserOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const token = location.state?.token || localStorage.getItem("token");

  const getAdminData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getadmin`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data.user);
    } catch (error) {
      console.log("Error fetching admin data:", error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getalluser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.users);
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const getUserOrders = async () => {
    try {
      const response = await axios.get(`${API}/admin/getallorders`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const ordersByUser = {};
      response.data.orders.forEach((order) => {
        const userId = order.user; // Assuming the backend provides user ID as "user" field
        if (!ordersByUser[userId]) {
          ordersByUser[userId] = [];
        }
        ordersByUser[userId].push(order);
      });
      setUserOrders(ordersByUser);
    } catch (error) {
      console.log("Error fetching user orders:", error);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      const fetchData = async () => {
        setLoading(true);
        await getAdminData();
        await getUserData();
        await getUserOrders();
        setLoading(false);
      };
      fetchData();
    }
  }, [token, navigate]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUserData = searchTerm
    ? userData.filter((user) => user.phoneNumber.includes(searchTerm))
    : userData;

  return (
    <>
      <AdminNavbar adminData={adminData} />
      <div
        className="container-fluid "
        style={{
          marginTop: "90px",
        }}
      >
        <h2 className="heading">Customer Data</h2>
        <div
          className="container d-flex justify-content-between mb-3"
          style={{ gap: "1.2rem" }}
        >
          <InputGroup className="search-bar">
            <FormControl
              placeholder="Search Mobile Number"
              aria-label="Search Mobile Number"
              aria-describedby="basic-addon2"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                borderRadius: "30px",
                padding: "10px 20px",
                border: "1px solid #ced4da",
              }}
            />
          </InputGroup>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{
          border: "2px groove #EEEEEE",
          // maxHeight: "430px",
          scrollbarWidth: "thin",
          padding: "0",
        }}
      >
        <table
          className="table table-hover table-fluid"
          style={{ margin: "0" }}
        >
          <thead
            style={{
              position: "sticky",
              top: "0",
              zIndex: "1",
              background: "#fff",
            }}
          >
            <tr>
              <th style={{ padding: "5px" }}>#</th>
              <th style={{ padding: "5px" }}>User Name</th>
              <th style={{ padding: "5px" }}>Mobile Number</th>
              <th style={{ padding: "5px" }}>Email Id</th>
              <th style={{ padding: "5px" }}>Register Date</th>
              <th style={{ padding: "5px" }}>Total Orders</th>
              <th style={{ padding: "5px" }}>Payed Orders</th>
              <th style={{ padding: "5px" }}>UnPayed Orders</th>
              <th style={{ padding: "5px" }}>View Orders</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : filteredUserData.length > 0 ? (
              filteredUserData.map((user, index) => (
                <tr key={user._id}>
                  <td style={{ padding: "5px" }}>{index + 1}</td>
                  <td style={{ padding: "5px" }}>{user.userName}</td>
                  <td style={{ padding: "5px" }}>
                    <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
                  </td>

                  <td style={{ padding: "5px" }}>
                    {user.email ? (
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    ) : (
                      "No email given"
                    )}
                  </td>

                  <td style={{ padding: "5px", fontSize: "12px" }}>
                    {new Date(user.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                    <br />
                    {new Date(user.createdAt).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </td>

                  <td style={{ padding: "5px" }}>
                    {userOrders[user._id]?.length || 0}
                  </td>
                  <td style={{ padding: "5px" }}>
                    {userOrders[user._id]?.filter(
                      (order) => order.status == "Paid"
                    ).length || 0}
                  </td>
                  <td style={{ padding: "5px" }}>
                    {userOrders[user._id]?.filter(
                      (order) => !(order.status == "Paid")
                    ).length || 0}
                  </td>
                  <td style={{ padding: "5px" }}>
                    <BiSolidShow
                      onClick={() =>
                        navigate(`/admin/userorders/${user._id}`, {
                          state: { token },
                        })
                      }
                      style={{
                        padding: "5px",
                        fontSize: "34px",
                        color: "#10439F",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  {/* <td>
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-edit-${user._id}`}>Edit</Tooltip>}
                    >
                      <BiSolidEdit className="me-3" style={{ cursor: "pointer" }} />
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-delete-${user._id}`}>Delete</Tooltip>}
                    >
                      <MdDeleteForever className="me-3" style={{ cursor: "pointer" }} />
                    </OverlayTrigger>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No users found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserData;
