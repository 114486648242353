import React, { useState, useEffect } from 'react';
// import './TextSlider.css';

const TextSlider = ({ texts, interval = 2000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === texts.length - 1 ? 0 : prevIndex + 1));
    }, interval);

    return () => clearInterval(sliderInterval);
  }, [texts, interval]);

  return (
    <div className="text-textslider" style={{marginTop:"80px"}}>
      {texts.map((text, idx) => (
        <div key={idx} className={`textslide ${idx === index ? 'active' : ''}`}>
          {text}
        </div>
      ))}
    </div>
  );
};

export default TextSlider;
