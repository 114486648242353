import React, { useState, useEffect } from "react";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import API from "../../api/API";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import {
  Tooltip,
  OverlayTrigger,
  Dropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { IoMdDoneAll } from "react-icons/io";
import { toast } from "react-toastify";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import OrderData from "../admin/OrderData";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const OrderSummary = () => {
  const [cart, setCart] = useState([]);
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderSummary, setOrderSummary] = useState([]);
  const [orderItemSummary, setOrderItemSummary] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index, isIconClick = false) => {
    if (isIconClick && expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const token = location.state?.token || localStorage.getItem("token");
  console.log(token);

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        // Token is invalid or expired
        localStorage.removeItem("token");
      } else {
        console.error("Failed to fetch user data:", error);
      }
    }
  };

  const getProductData = async () => {
    try {
      const response = await axios.get(`${API}/product/showproducts`);
      const products = {};
      response.data.products.forEach((product) => {
        products[product._id] = product;
      });
      setProductData(products);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
    getUserData();
    getProductData();
  }, []);

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const getOrderSummary = async () => {
    try {
      const response = await axios.get(`${API}/users/orders`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const paidOrders = response.data.orders.filter(
        (order) => order.status === "Paid"
      );
      // Sort the orders by createdAt in reverse order
      const sortedOrders = paidOrders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrderSummary(sortedOrders);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };
  const getOrderItemsSummary = async () => {
    try {
      const response = await axios.get(`${API}/users/orderItems`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrderItemSummary(response.data.orderItems);
      // console.log(response.data.orderItems);
      // console.log(orderItemSummary);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };

  useEffect(() => {
    getOrderSummary();
    getOrderItemsSummary();
  }, [token]);

  const getStatusClass = (currentStatus, status) => {
    const statusOrder = [
      "orderPicked",
      "orderPacked",
      "orderDespatched",
      "bookingSuccessful",
    ];
    const currentStatusIndex = statusOrder.indexOf(currentStatus);
    const statusIndex = statusOrder.indexOf(status);

    // return statusIndex <= currentStatusIndex ? "completed green" : "";
  };

  const handleBookingIdClick = () => {
    navigator.clipboard.writeText(orderSummary.bookingId);
    toast.info("Booking ID copied to clipboard");
  };

  return (
    <>
      <NavbarComponent
        totalQuantityInCart={totalQuantityInCart}
        token={token}
        userName={userData.userName}
      />
      <section
        className="h-100 gradient-custom-2 section"
        style={{ marginTop: "75px" }}
      >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div
                className="card"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                }}
              >
                <div className="order-container">
                  <h2 className="text-center mt-4">Order Summary</h2>
                  {loading ? (
                    // <div className="text-center">
                    //   <Spinner animation="border" role="status">
                    //     <span className="sr-only">Loading...</span>
                    //   </Spinner>
                    // </div>
                    <Skeleton count={4} height={70} style={{ marginBottom: '25px' }} />
                  ) : orderSummary.length === 0 ? (
                    <div className="text-center">
                      <p>No orders found.</p>
                      <Button
                        variant="primary"
                        onClick={() => navigate("/explore")}
                      >
                        Start Shopping
                      </Button>
                    </div>
                  ) : (
                    <div className="order-summary ">
                      {orderSummary.map((order, index) => (
                        <>
                          <div
                            key={index}
                            // style={{ marginBottom: "70px" }}
                            className={`order-item ${order.bookingId ? 'border-green' : ''}`}
                            onClick={() => toggleExpand(index)}
                          >
                            <div>
                              <p style={{ margin: "0", padding: "0" }}>
                                Your Order #
                                <span style={{ fontWeight: "bold" }}>
                                  {order.orderID}
                                </span>{" "}
                                was placed on{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {new Date(
                                    order.createdAt
                                  ).toLocaleDateString()}
                                </span>{" "}
                                and Payment Status:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {order.status}
                                </span>
                                .
                              </p>
                              <span
                                className="faq-toggle-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleExpand(index, true);
                                }}
                                style={{ cursor: "pointer", width: "100%" }}
                              >
                                {expandedIndex === index ? (
                                  <FaChevronUp />
                                ) : (
                                  <FaChevronDown />
                                )}
                              </span>
                            </div>
                            {expandedIndex === index && (
                              <>
                                <div className="order-item">
                                  <div className="order-header">
                                    <h6>Order ID: {order.orderID}</h6>
                                    <h6>
                                      Payment ID:{" "}
                                      {order.paymentId.split("_")[1]}
                                    </h6>
                                    {/* <Button variant="success">Track Order</Button> */}
                                  </div>
                                  <div className="order-items">
                                    <h5>Order Items:</h5>
                                    {order.orderItems.map((orderItem, i) => {
                                      const orderID = orderItemSummary.find(
                                        (item) => item?._id === orderItem
                                      );
                                      // const product = productData[orderID.productId];
                                      const product = orderID?.productId
                                        ? productData[orderID.productId]
                                        : null;

                                      if (!product) {
                                        // Handle the case where product is not found
                                        return <p>Product not found</p>;
                                      }

                                      return (
                                        <div key={i} className="order-product">
                                          {product ? (
                                            <div className="product-info rounded-top d-flex flex-row justify-content-around">
                                              <div className="d-flex flex-column col-3 ">
                                                <img
                                                  src={product.productImage}
                                                  alt={product.productTitle}
                                                  className="product-image"
                                                />
                                              </div>
                                              <div
                                                className="d-flex flex-column col-7 p-2"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                  justifyContent: "flex-start",
                                                }}
                                              >
                                                <h5
                                                  style={{ fontSize: "0.9rem" }}
                                                >
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-title-${product._id}`}
                                                      >
                                                        {product.productTitle}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      {product.productTitle
                                                        .length > 25
                                                        ? product.productTitle.slice(
                                                            0,
                                                            25
                                                          ) + "..."
                                                        : product.productTitle}
                                                    </span>
                                                  </OverlayTrigger>
                                                </h5>
                                                <p
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    fontSize: "0.7rem",
                                                  }}
                                                >
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-${product._id}`}
                                                      >
                                                        {product.productContent}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      {product.productContent
                                                        .length > 25
                                                        ? product.productContent.slice(
                                                            0,
                                                            25
                                                          ) + "..."
                                                        : product.productContent}
                                                    </span>
                                                  </OverlayTrigger>
                                                </p>
                                              </div>
                                              <div className="d-flex flex-column col-2 ">
                                                <h5
                                                  style={{
                                                    marginTop: "8px",
                                                    fontSize: "1rem",
                                                  }}
                                                >
                                                  &#8377;{product.newPrice}
                                                </h5>
                                                <p
                                                  style={{ fontSize: "0.8rem" }}
                                                >
                                                  Qty:{" "}
                                                  {
                                                    orderItemSummary.find(
                                                      (item) =>
                                                        item._id === orderItem
                                                    )?.quantity
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          ) : (
                                            <p>
                                              Product details not available for
                                              ID: {orderItem}
                                            </p>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    className="order-product d-flex justify-content-between"
                                    style={{ padding: 0, fontSize: "small" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <p style={{ margin: 0 }}>Total Price:</p>
                                      <p style={{ margin: 0 }}>
                                        Delivery Charges:
                                      </p>
                                      <h6 style={{ margin: 0 }}>
                                        Grand Total:
                                      </h6>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <p style={{ margin: 0 }}>
                                        &#8377;{order.totalPrice}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        {order.deliveryCharge === 0
                                          ? "Free"
                                          : `₹${order.deliveryCharge}`}
                                      </p>

                                      <h6 style={{ margin: 0 }}>
                                        &#8377;{order.grandTotal}
                                      </h6>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="order-address"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "10px",
                                    fontSize:"0.8rem"
                                    // gap: "6px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Address:{" "}
                                  </h6>
                                  <p style={{ margin: "0", padding: "0" }}>
                                    Name:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {order.address.name}
                                    </span>
                                  </p>

                                  <p
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                    }}
                                  >
                                    Address:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {order.address.address},
                                    </span>
                                    {<br></br>}
                                    City:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {order.address.city},
                                    </span>
                                    {<br></br>}
                                    State:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {order.address.state},
                                    </span>
                                    {<br></br>}
                                    Zip:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {order.address.zip}
                                    </span>
                                  </p>
                                  <p style={{ margin: "0", padding: "0" }}>
                                    Mobile No:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {userData.phoneNumber}
                                    </span>
                                  </p>
                                  <p style={{ margin: "0", padding: "0" }}>
                                    Email:{" "}
                                    <span style={{ color: "#686D76" }}>
                                      {userData.email}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className="timeline"
                                  // onClick={() => {
                                  //   toggleExpand(index);
                                  // }}
                                >
                                  {[
                                    "orderPicked",
                                    "orderPacked",
                                    "orderDespatched",
                                    "bookingSuccessful",
                                  ].map((statusKey) => (
                                    <div
                                      key={statusKey}
                                      className={`timeline-event ${getStatusClass(
                                        order.orderStatus,
                                        statusKey
                                      )} ${
                                        expandedIndex === index ? "" : "dim"
                                      }`}
                                    >
                                      <div
                                        className={`timeline-content ${
                                          expandedIndex === index
                                            ? ""
                                            : "dim-text"
                                        }`}
                                      >
                                        <p
                                          className={`name ${
                                            order.orderStatusDates?.[statusKey]
                                              ? "text-primary"
                                              : "text-muted"
                                          }`}
                                        >
                                          {
                                            statusKey
                                              .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
                                              .replace(/^./, (str) =>
                                                str.toUpperCase()
                                              ) // Capitalize the first letter
                                              .replace(/ (.)/g, "\n$1") // Replace spaces with newlines for a two-line result
                                          }
                                        </p>
                                      </div>
                                      <div
                                        className={`timeline-marker ${
                                          order.orderStatusDates?.[statusKey]
                                            ? "completed"
                                            : "uncompleted"
                                        }`}
                                      >
                                        {order.orderStatusDates?.[
                                          statusKey
                                        ] && (
                                          <IoMdDoneAll
                                            style={{
                                              color: "#050C9C",
                                              fontSize: "24px",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div className="date-time">
                                        {order.orderStatusDates?.[
                                          statusKey
                                        ] && (
                                          <p className="date">
                                            {new Date(
                                              order.orderStatusDates[statusKey]
                                            ).toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                            })}
                                            <br />
                                            {new Date(
                                              order.orderStatusDates[statusKey]
                                            ).toLocaleTimeString("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {order.serviceName && order.bookingId && (
                                    <>
                                      <div
                                        className="order-product d-flex justify-content-between"
                                        style={{
                                          padding: 0,
                                          fontSize: "small",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <p style={{ margin: 0 }}>
                                            Transport Service Name:
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Booking ID:
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <p style={{ margin: 0 }}>
                                            {order.serviceName}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                fontWeight: "bold",
                                              }}
                                              onClick={handleBookingIdClick}
                                            >
                                              {order.bookingId}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="faq-toggle-icon"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleExpand(index, true);
                                        }}
                                      >
                                        <FaChevronUp />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <hr></hr>
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OrderSummary;
