import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Card,
  Button,
  FormControl,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { toast } from "react-toastify";
import API from "../../api/API.js";
import axios from "axios";
import cart1 from "../../assests/cart1.png";
import { MdAddShoppingCart } from "react-icons/md";
import { AiOutlineSafety } from "react-icons/ai";
import logo from "../../assests/logo001.jpg";
import { SiRazorpay } from "react-icons/si";
import loader from "../../assests/loading1.gif";
import { Helmet } from "react-helmet";

const Cart = () => {
  const [cart, setCart] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [isShippingAddressVisible, setIsShippingAddressVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrderSummary, setIsLoadingOrderSummary] = useState(false);

  const handleBuyNow = () => {
    if (!token) {
      toast.warning("You need to Login First", { autoClose: 3000 });
      navigate("/login");
      return;
    }

    setIsShippingAddressVisible(true);
  };

  const handleZipChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      // Only allow digits
      handleAddressChange(event);
    }
  };

  const statesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const [address, setAddress] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    if (userData.userName || userData.email) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        name: userData.userName || "",
        email: userData.email || "",
        address: userData.address?.address || "",
        city: userData.address?.city || "",
        state: userData.address?.state || "",
        zip: userData.address?.zip || "",
      }));
    }
  }, [userData]);

  const addressInputRef = useRef(null);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
  //   script.async = true;
  //   script.onload = () => handleScriptLoad();
  //   document.body.appendChild(script);
  // }, []);

  const handleScriptLoad = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInputRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: "in" },
      }
    );
    autocomplete.setFields(["address_component"]);
    autocomplete.addListener("place_changed", () =>
      handlePlaceSelect(autocomplete)
    );
  };

  const handlePlaceSelect = (autocomplete) => {
    const addressObject = autocomplete.getPlace();
    const addressComponents = addressObject.address_components;
    const updatedAddress = { ...address };

    addressComponents.forEach((component) => {
      const componentType = component.types[0];
      switch (componentType) {
        case "locality":
          updatedAddress.city = component.long_name;
          break;
        case "administrative_area_level_1":
          updatedAddress.state = component.long_name;
          break;
        case "postal_code":
          updatedAddress.zip = component.long_name;
          break;
        case "street_number":
          updatedAddress.address = `${component.long_name} ${updatedAddress.address}`;
          break;
        case "route":
          updatedAddress.address = `${updatedAddress.address} ${component.long_name}`;
          break;
        default:
          break;
      }
    });

    setAddress(updatedAddress);
  };

  const token = location.state?.token || localStorage.getItem("token");
  // console.log(token);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        // Token is invalid or expired
        localStorage.removeItem("token");
      } else {
        console.error("Failed to fetch user data:", error);
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleInputChange = (index, e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the entered value is between 0 and 99
    if (value >= 0 && value <= 99) {
      setCart((prevCart) =>
        prevCart.map((item, i) =>
          i === index ? { ...item, quantity: value } : item
        )
      );
    }
  };

  const incrementQuantity = (index) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item, i) =>
        i === index && item.quantity < 99
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const decrementQuantity = (index) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item, i) =>
        i === index && item.quantity > 0
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
      const filteredCart = updatedCart.filter((item) => item.quantity > 0);
      localStorage.setItem("cart", JSON.stringify(filteredCart));
      return filteredCart;
    });
  };

  const calculateTotalPrice = (price, quantity) => {
    return price * quantity;
  };

  const calculateTotal = () => {
    const total = cart.reduce(
      (total, item) =>
        total + calculateTotalPrice(item.product.newPrice, item.quantity),
      0
    );
    return total;
  };

  const calculateLoading = () => {
    return 0; // Free delivery charge
  };

  const calculateDeliveryCharge = () => {
    const total = calculateTotal();
    return total > 5000 ? calculateLoading() : 50;
  };

  const calculateGrandTotal = () => {
    const total = calculateTotal();
    const deliveryCharge = calculateDeliveryCharge();
    return total + deliveryCharge;
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handlePlaceOrder = async () => {
    setIsLoading(true);
    try {
      // Calculate totals
      const total = calculateTotal();
      const deliveryCharge = calculateDeliveryCharge();
      const grandTotal = calculateGrandTotal();

      // Prepare data to be sent
      const orderData = {
        cart: cart.map((item) => ({
          productId: item.product._id,
          quantity: item.quantity,
        })),
        address: address,
        user: userData,
        totalPrice: total,
        deliveryCharge: deliveryCharge,
        grandTotal: grandTotal,
      };
      const userMail = address.email;
      // console.log("Order Data: ", orderData);

      // Make POST request to the backend
      const response = await axios.post(`${API}/users/placeorder`, orderData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Check if the order was successfully placed
      if (response.status === 200) {
        const orderId = response.data.orderId;
        const newOrder = response.data.newOrder;
        const orderItem = response.data.orderItem;
        if (orderId && newOrder && orderItem) {
          handlePayment(orderId, newOrder, orderItem, userMail, orderData);
        } else {
          console.error(
            "Order ID or Order Item missing in response:",
            response.data
          );
          toast.error("Failed to place order. Please try again later.");
        }
      } else {
        // Show error message
        toast.error("Failed to place order. Please try again later.");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      // Show error message
      toast.error("Failed to place order. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = (orderId, newOrder, orderItem, userMail, orderData) => {
    const grandTotal = calculateGrandTotal();

    var options = {
      key: "rzp_test_yVFC0dAfJqi4go",
      key_secret: "uoWIkG79ozIzQxctYOofgn19",
      amount: grandTotal * 100,
      currency: "INR",
      name: "Salluuu Pet's",
      description: "for testing purpose",
      image: logo,
      handler: function (response) {
        alert("Your Payment ID: " + response.razorpay_payment_id.split("_")[1]);
        toast.success("Payment Successful!");

        const payData = {
          paymentId: response.razorpay_payment_id,
          amount: grandTotal,
          user: userData,
          orderId: orderId,
          newOrder: newOrder,
          orderItem: orderItem,
        };
        const paymentId = response.razorpay_payment_id;

        // console.log(payData);

        // Post payment data for verification
        axios
          .post(`${API}/users/verify-payment`, payData)
          .then(() => {
            // Once payment is verified, update order status to 'Paid'
            updateOrderStatus(
              orderId,
              paymentId,
              userData,
              userMail,
              orderData
            );
          })
          .catch((error) => {
            console.error("Error posting payment ID:", error);
            toast.error("Failed to process payment.");
          });
      },
      prefill: {
        name: userData.userName,
        contact: userData.phoneNumber,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();
  };

  const updateOrderStatus = async (
    orderID,
    paymentId,
    userData,
    userMail,
    orderData
  ) => {
    setIsLoadingOrderSummary(true);
    try {
      await axios.put(
        `${API}/users/update-order-status/${orderID}`, // Include userId in the URL
        { status: "Paid", paymentId: paymentId, userData, userMail, orderData }, // Only include the status in the request body
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setTimeout(() => {
        navigate(`/orderSummary/${userData._id}`);
        localStorage.removeItem("cart");
        setCart([]);
      }, 500);
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Failed to update order status.");
    } finally {
      setIsLoadingOrderSummary(false); // Hide loader after navigation
    }
  };

  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <Helmet>
        <title>Salluuu Pets Cart: Easy Checkout & Secure Payments</title>
        <meta
          name="description"
          content="Review your selections, check prices, and proceed to secure checkout on the Salluuu Pets Cart Page. Ensure you have all the pet products and accessories you need for a hassle-free shopping experience."
        />
      </Helmet>
      <NavbarComponent
        token={token}
        userName={userData.userName}
        totalQuantityInCart={totalQuantityInCart}
      />
      {isLoadingOrderSummary ? (
        <div style={{ textAlign: "center", marginTop: "120px" }}>
          <img
            src={loader}
            alt="Loading..."
            style={{ width: "250px", height: "250px" }}
          />
          <p>Processing your payment, please wait...</p>
        </div>
      ) : (
        <>
          <Container
            className="text-center p-2 rounded shadow-sm"
            style={{
              marginTop: "90px",
              // backgroundColor: "#f8f9fa",
              color: "#333",
              // border: "1px solid #ddd",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              className="mb-2"
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                color: "#007bff",
                fontFamily: "sans-serif",
              }}
            >
              Welcome {userData.userName}
            </h2>
            {/* <p style={{ fontSize: "1.25rem", color: "#555" }}>
        We're glad to have you here! Explore and enjoy our services.
      </p> */}
          </Container>

          {cart.length > 0 ? (
            <div className="cart">
              <div className="left">
                <Container className="mt-3">
                  <h3>Your Cart Items</h3>
                  {cart.map((item, index) => (
                    <Card key={index} className="mb-3">
                      <Row className="no-gutters">
                        <Col
                          xs={3}
                          md={3}
                          className="text-center"
                          style={{ padding: "2px" }}
                        >
                          <Card.Img
                            src={item.product.productImage}
                            alt={item.product.productTitle}
                            style={{
                              width: "60px",
                              height: "90px",
                              objectFit: "cover",
                              cursor: "pointer",
                              borderRadius: "10px",
                              margin: "0.2rem",
                            }}
                          />
                        </Col>
                        <Col xs={5} md={5} style={{ padding: "2px" }}>
                          <Card.Body
                            style={{ padding: "2px", margin: "1rem 0.2rem" }}
                          >
                            <Card.Title style={{ fontSize: "0.8rem" }}>
                              {item.product.productTitle}
                            </Card.Title>
                            <Card.Text style={{ fontSize: "0.7rem" }}>
                              Price: &#8377;{item.product.newPrice}
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginLeft: "15px",
                                  fontSize: "10px",
                                  color: "#888",
                                }}
                              >
                                &#8377;{item.product.mrpPrice}
                              </span>
                            </Card.Text>
                          </Card.Body>
                        </Col>
                        <Col
                          xs={3}
                          md={3}
                          className="text-center"
                          style={{ padding: "2px" }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center mb-2"
                            style={{ margin: "1rem 0.2rem" }}
                          >
                            <Button
                              variant="outline-primary"
                              onClick={() => decrementQuantity(index)}
                              style={{
                                borderRadius: "50px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              <FaMinus
                                style={{
                                  fontSize: "10px",
                                  margin: "4px",
                                  padding: "0",
                                }}
                              />
                            </Button>
                            <FormControl
                              // type="number"
                              type="text"
                              value={item.quantity}
                              onChange={(e) => handleInputChange(index, e)}
                              style={{
                                margin: "0 0.2rem",
                                textAlign: "center",
                                width: "3rem",
                                border: "2px solid #386bc0",
                                borderRadius: "10px",
                                height: "24px",
                                padding: "0",
                                textAlign: "center",
                              }}
                              max={99}
                            />
                            <Button
                              variant="outline-primary"
                              onClick={() => incrementQuantity(index)}
                              style={{
                                borderRadius: "50px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              <FaPlus
                                style={{
                                  fontSize: "10px",
                                  margin: "4px",
                                  padding: "0",
                                }}
                              />
                            </Button>
                          </div>
                          <h6 style={{ margin: "7px", fontSize: "0.8rem" }}>
                            Total Price: &#8377;
                            {calculateTotalPrice(
                              item.product.newPrice,
                              item.quantity
                            )}
                          </h6>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <div
                    className="container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "15px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Link to="/explore" style={{ textDecoration: "none" }}>
                      <button
                        className="btn btn-primary"
                        style={{
                          color: "#fff",
                          fontSize: "1rem",
                          backgroundColor: "#0A66C2",
                          borderRadius: "50px",
                          width: "100%",
                          margin: "0",
                          border: "none",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease-in-out",
                          padding: "0.5rem 2rem",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.backgroundColor = "#095da7")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.backgroundColor = "#0A66C2")
                        }
                      >
                        <MdAddShoppingCart
                          style={{ fontSize: "1.2rem", marginRight: "10px" }}
                        />
                        Add More Products
                      </button>
                    </Link>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <p style={{ margin: 0, color: "green" }}>
                      Purchase above &#8377;5000 delivery charge will be free
                    </p>
                  </div>
                  <div
                    className="text-right mt-4"
                    style={{
                      padding: "15px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div className="d-flex justify-content-end align-items-center mb-2">
                      <h6 className="mr-2">Total Price:</h6>
                      <h6>&#8377; {calculateTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-2">
                      <h6 className="mr-2">Delivery Charge:</h6>
                      <h6>
                        {calculateDeliveryCharge() === 0
                          ? "Free"
                          : `₹ ${calculateDeliveryCharge()}`}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                      <h4 className="mr-2">Grand Total:</h4>
                      <h4>&#8377;{calculateGrandTotal()}</h4>
                    </div>
                  </div>
                  {!isShippingAddressVisible && (
                    <div className="text-right mt-4">
                      <button
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#0A66C2",
                          borderRadius: "50px",
                          width: "100%",
                        }}
                        onClick={handleBuyNow}
                      >
                        {token ? "Buy Now" : "Login & Buy Now"}
                      </button>
                    </div>
                  )}
                </Container>
              </div>
              {isShippingAddressVisible && (
                <div className="right">
                  <Container
                    className="mt-3"
                    style={{
                      backgroundColor: "#f8f9fa",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <h3
                      style={{
                        color: "#0A66C2",
                        marginBottom: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Shipping Address
                    </h3>
                    <Form>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          Name:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={address.name}
                          onChange={handleAddressChange}
                          placeholder="Enter your name"
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          isInvalid={!address.name}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Please provide your name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          Email:
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={address.email}
                          onChange={handleAddressChange}
                          placeholder="Enter your email"
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          isInvalid={
                            !address.email ||
                            !/\S+@\S+\.\S+/.test(address.email)
                          }
                        />

                        {(!address.email ||
                          !/\S+@\S+\.\S+/.test(address.email)) && (
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          Address:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          value={address.address}
                          onChange={handleAddressChange}
                          placeholder="Enter Door No & Street Name"
                          ref={addressInputRef}
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          isInvalid={!address.address}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your address.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          City:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={address.city}
                          onChange={handleAddressChange}
                          placeholder="Enter city"
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          isInvalid={!address.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your city.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          State:
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="state"
                          value={address.state}
                          onChange={handleAddressChange}
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          isInvalid={!address.state}
                        >
                          <option value="">Select state</option>
                          {statesOfIndia.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide your state.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        style={{
                          textAlign: "left",
                          alignContent: "left",
                        }}
                      >
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            color: "#0A66C2",
                            marginTop: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          ZIP Code:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="zip"
                          value={address.zip}
                          onChange={handleZipChange}
                          placeholder="Enter ZIP code"
                          style={{
                            borderRadius: "8px",
                            border: "2px solid #007bff",
                            marginBottom: "15px",
                            transition: "border-color 0.3s ease-in-out",
                          }}
                          maxLength="6"
                          pattern="\d{6}"
                          isInvalid={
                            !address.zip || !/^\d{6}$/.test(address.zip)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid ZIP code.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handlePlaceOrder}
                        style={{
                          backgroundColor: "#0A66C2",
                          borderRadius: "50px",
                          width: "100%",
                          fontSize: "1.2em",
                        }}
                        disabled={
                          !address.name ||
                          !address.email ||
                          !/\S+@\S+\.\S+/.test(address.email) ||
                          !address.address ||
                          !address.city ||
                          !address.state ||
                          !address.zip ||
                          isLoading
                        }
                      >
                        {isLoading ? (
                          <>
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Loading...</span>
                          </>
                        ) : (
                          <>
                            <AiOutlineSafety
                              style={{ fontSize: "24px", marginBottom: "3px" }}
                            />{" "}
                            Place Order
                          </>
                        )}
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyItems: "center",
                          marginTop: "20px",
                          padding: "10px",
                          backgroundColor: "#f8f9fa",
                          color: "#333",
                          borderRadius: "8px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1rem",
                            color: "#555",
                            marginTop: "7px",
                          }}
                        >
                          You can pay once your order is out for delivery.
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            color: "#555",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AiOutlineSafety
                            style={{
                              color: "green",
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Safe and secure payments. Your card details are not
                          stored.
                        </p>
                      </div>
                    </Form>
                  </Container>
                </div>
              )}
            </div>
          ) : (
            <Container
              className="d-flex flex-column align-items-center justify-content-center text-center p-4"
              style={{
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={cart1}
                alt="Empty Cart"
                style={{
                  height: "250px",
                  width: "250px",
                  // borderRadius: "50%",
                  transition: "transform 0.3s ease-in-out",
                  filter: "drop-shadow(5px 5px 40px #007bff)",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.1)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1.0)")
                }
              />
              <p style={{ fontSize: "1.2em", color: "#555", margin: "20px 0" }}>
                Your cart is empty, Go ahead and add something to the cart
              </p>
              <Link
                to="/explore"
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  fontSize: "1em",
                  color: "#fff",
                  backgroundColor: "#007bff",
                  borderRadius: "5px",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease-in-out",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#007bff")
                }
              >
                Start Shopping
              </Link>
            </Container>
          )}
        </>
      )}

      <Footer />
    </>
  );
};

export default Cart;
