import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import API from "../../api/API";
import { Spinner, Button } from "react-bootstrap";
import {
  Tooltip,
  OverlayTrigger,
  Dropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { RiArrowGoBackFill } from "react-icons/ri";
import { SiEsotericsoftware } from "react-icons/si";
import UserData from "./UserData";

const UserOrder = () => {
  const [adminData, setAdminData] = useState({});
  const [user, setUser] = useState({});
  const [userOrders, setUserOrders] = useState([]);
  const [userOrderItems, setUserOrderItems] = useState([]);
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderSummary, setOrderSummary] = useState([]);
  const [orderItemSummary, setOrderItemSummary] = useState([]);
  const [filter, setFilter] = useState("All");

  const { userId } = useParams();
  const location = useLocation(); // Define location after useParams
  const { pathname } = location;
  const userID = pathname.split("/")[3] || null;
  // console.log(userID);

  const navigate = useNavigate();

  const token = location.state?.token || localStorage.getItem("token");

  const getAdminData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getadmin`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data.user);
    } catch (error) {
      console.log("Error fetching admin data:", error);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      getAdminData();
    }
  }, [token, navigate]);

  const getUser = async () => {
    try {
      const response = await axios.get(`${API}/admin/getuser/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      console.log("Error fetching user orders:", error);
    }
  };

  const getUserOrders = async (userID) => {
    try {
      setLoading(true); // Set loading to true when fetching orders
      const response = await axios.get(`${API}/admin/getuserorders/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // setUserOrders(
      //   response.data.orders.sort(
      //     (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      //   )
      // );
      const sortedOrders = response.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setUserOrders(sortedOrders);
    } catch (error) {
      console.log("Error fetching user orders:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const getUserOrderItems = async () => {
    try {
      const response = await axios.get(
        `${API}/admin/getuserorderitems/${userID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const sortedOrders = response.data.orderItems.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setUserOrderItems(sortedOrders);
      // setUserOrderItems(response.data.orderItems);
    } catch (error) {
      console.log("Error fetching user orders:", error);
    }
  };

  useEffect(() => {
    getUser(userID);
    getUserOrders(userID);
    getUserOrderItems(userID);
  }, [userId, token]);

  const getProductData = async () => {
    try {
      const response = await axios.get(`${API}/product/showproducts`);
      const products = {};
      response.data.products.forEach((product) => {
        products[product._id] = product;
      });
      setProductData(products);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  const getOrderSummary = async () => {
    try {
      const response = await axios.get(`${API}/admin/orders/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedOrders = response.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrderSummary(sortedOrders);
      // setOrderSummary(response.data.orders);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };
  const getOrderItemsSummary = async () => {
    try {
      const response = await axios.get(`${API}/admin/orderItems/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedOrders = response.data.orderItems.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrderItemSummary(sortedOrders);
      // setOrderItemSummary(response.data.orderItems);
      // console.log(response.data.orderItems);
      // console.log(orderItemSummary);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };

  useEffect(() => {
    getProductData();
    getOrderSummary();
    getOrderItemsSummary();
  }, []);

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
  };

  const filteredOrders = orderSummary.filter((order) => {
    if (filter === "All") return true;
    if (filter === "Paid") return order.status === "Paid";
    if (filter === "Unpaid") return !(order.status === "Paid");
    return true;
  });

  return (
    <>
      <AdminNavbar adminData={adminData} />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyItems: "flex-start",
          }}
        >
          <div className="text-center mt-3 mb-3">
            <Link
              style={{ fontSize: "24px", textDecoration: "underline" }}
              to={`/userdata/${user._id}`}
            >
              <RiArrowGoBackFill style={{ fontSize: "24px" }} /> Back to Profile
            </Link>
          </div>
          <h2>Name: {user.userName}</h2>
          <h4>Mobile: {user.phoneNumber}</h4>
          <h6>Email: {user.email ? user.email : ""}</h6>
        </div>
        <div className="order-container">
          <h1 className="text-center mt-4">Order Summary</h1>
          {/* <div className="filter-buttons text-center">
            <Button
              variant={filter === "All" ? "primary" : "outline-primary"}
              onClick={() => handleFilterChange("All")}
              className="m-2"
            >
              All
            </Button>
            <Button
              variant={filter === "Paid" ? "primary" : "outline-primary"}
              onClick={() => handleFilterChange("Paid")}
              className="m-2"
            >
              Paid
            </Button>
            <Button
              variant={filter === "Unpaid" ? "primary" : "outline-primary"}
              onClick={() => handleFilterChange("Unpaid")}
              className="m-2"
            >
              Unpaid
            </Button>
          </div> */}
          <div className="filter-dropdown text-start">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Filter Orders
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleFilterChange("All")}>
                  All
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange("Paid")}>
                  Paid
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange("Unpaid")}>
                  Unpaid
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="order-summary">
              {filteredOrders.length > 0 ? (
                filteredOrders.map((order, index) => (
                  <div key={index} style={{ marginBottom: "70px" }}>
                    <p>
                      Order #
                      <span style={{ fontWeight: "bold" }}>
                        {order.orderID}
                      </span>{" "}
                      was placed on{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {new Date(order.createdAt).toLocaleString()}
                      </span>{" "}
                      and is currently Payment Status{" "}
                      <span style={{ fontWeight: "bold" }}>{order.status}</span>
                      .
                    </p>
                    <div className="order-item">
                      <div className="order-header">
                        <h5>Order ID: {order.orderID}</h5>
                        {order.paymentId && (
                          <h5>Payment ID: {order.paymentId.split("_")[1]}</h5>
                        )}
                      </div>
                      <div className="order-items">
                        <h5>Order Items:</h5>
                        {order.orderItems.map((orderItem, i) => {
                          const orderID = orderItemSummary.find(
                            (item) => item?._id === orderItem
                          );
                          const product = orderID?.productId
                            ? productData[orderID.productId]
                            : null;

                          if (!product) {
                            return <p key={i}>Product not found</p>;
                          }

                          return (
                            <div key={i} className="order-product">
                              <div className="product-info rounded-top d-flex flex-row justify-content-around">
                                <div className="d-flex flex-column col-3">
                                  <img
                                    src={product.productImage}
                                    alt={product.productTitle}
                                    className="product-image"
                                  />
                                </div>
                                <div
                                  className="d-flex flex-column col-7 p-2"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <h5 style={{ fontSize: "18px" }}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-title-${product._id}`}
                                        >
                                          {product.productTitle}
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {product.productTitle.length > 25
                                          ? product.productTitle.slice(0, 25) +
                                            "..."
                                          : product.productTitle}
                                      </span>
                                    </OverlayTrigger>
                                  </h5>
                                  <p
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-${product._id}`}>
                                          {product.productContent}
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {product.productContent.length > 25
                                          ? product.productContent.slice(
                                              0,
                                              25
                                            ) + "..."
                                          : product.productContent}
                                      </span>
                                    </OverlayTrigger>
                                  </p>
                                </div>
                                <div className="d-flex flex-column col-2">
                                  <h5>&#8377;{product.newPrice}</h5>
                                  <p>
                                    Qty:{" "}
                                    {
                                      orderItemSummary.find(
                                        (item) => item._id === orderItem
                                      )?.quantity
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="order-product d-flex justify-content-between"
                        style={{ padding: 0, fontSize: "small" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <p style={{ margin: 0 }}>Total Price:</p>
                          <p style={{ margin: 0 }}>Delivery Charges:</p>
                          <h6 style={{ margin: 0 }}>Grand Total:</h6>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <p style={{ margin: 0 }}>&#8377;{order.totalPrice}</p>
                          <p style={{ margin: 0 }}>
                            &#8377;{order.deliveryCharge}
                          </p>
                          <h6 style={{ margin: 0 }}>
                            &#8377;{order.grandTotal}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div
                      className="order-address"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "20px",
                        gap: "10px",
                      }}
                    >
                      <h5>Address: </h5>{" "}
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        {order.address.name}
                      </p>{" "}
                      <p>
                        {order.address.address}, {order.address.city},{" "}
                        {order.address.state}, {order.address.zip}
                      </p>
                    </div>
                    {user.instruction ? (
                      <p>Delivery Instruction: {user.instruction}</p>
                    ) : (
                      ""
                    )}

                    <hr />
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <p>No orders found.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserOrder;
