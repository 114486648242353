import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Alert,
  Toast,
} from "react-bootstrap";
import { storage } from "../Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import API from "../../api/API";
import { toast } from "react-toastify";
import AdminNavbar from "./AdminNavbar";
import { IoIosAlert } from "react-icons/io";
import { FaSmile } from "react-icons/fa";
import { MdBrowserUpdated } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";

const AddProducts = () => {
  const [productTitle, setProductTitle] = useState("");
  const [productContent, setProductContent] = useState("");
  const [mrpPrice, setMrpPrice] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [quantity, setQuantity] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [adminData, setAdminData] = useState({});

  const [image, setImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState("");

  const [img, setImg] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");

  const userID = adminData._id;
  const userName = adminData.userName;
  console.log(userID);
  console.log(userName);

  const token = location.state?.token || localStorage.getItem("token");
  // console.log(token);

  const { id } = useParams();

  const getAdminData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getadmin`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (!token) {
        navigate("/admin");
    } else {
      getAdminData();
    }
  }, [token]);

  const handlePublish = async (e) => {
    e.preventDefault();
    setUnsavedChanges(false);
    if (productTitle && productContent && mrpPrice && newPrice && quantity && productCategory && productSubCategory) {
      try {
        // Check if the image is uploaded before attempting to publish
        if (!imageUploaded) {
          toast.error("Please upload an image before publishing.");
          return;
        }
        setLoading(true);
        const productPostData = {
          productTitle,
          productContent,
          mrpPrice,
          newPrice,
          quantity,
          productCategory,
          productSubCategory,
          productImage: imageUrl,
          userID,
          userName,
        };
        console.log(productPostData)

        const response = await axios.post(
          `${API}/product/productpost`,
          productPostData
        );

        toast.success("Post published successfully!");
        navigate(`/dashboard/${adminData._id}`);
      } catch (error) {
        // console.error("Error:", error);
        toast.error("Failed to publish post.");
      } finally {
        setLoading(false); // Set loading to false when data loading is complete
      }
    } else {
      toast.error("Please fill in all the required fields.");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const uploadImage = async (userId) => {
    try {
      setUploading(true);

      if (image) {
        const storageRef = ref(
          storage,
          `productImages/${adminData._id}_${adminData.userName}_${image.name}`
        );
        await uploadBytes(storageRef, image);
        const downloadURL = await getDownloadURL(storageRef);
        setImageUrl(downloadURL);
        setImageUploaded(true);
        toast.success("Image uploaded successfully!");
      } else {
        toast.error("No image selected.");
      }

      setUploading(false);
    } catch (error) {
      // console.error(error);
      toast.error("Image upload failed");
      setUploading(false);
    }
  };

  
  const handleInputChange = () => {
    setUnsavedChanges(true);
  };


  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    if (unsavedChanges) {
      const message = "You have unsaved changes. Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    }
  };

  const handleMrpPriceChange = (e) => {
    const value = e.target.value;
    // Validate if the entered value is a positive number
    if (/^\d*$/.test(value)) {
      setMrpPrice(value);
    }
  };

  const handleNewPriceChange = (e) => {
    const value = e.target.value;
    // Validate if the entered value is a positive number
    // if (/^\d*\.?\d+$/.test(value) && parseFloat(value) >= 0) {
    //   setNewPrice(value);
    // }
    if (/^\d*$/.test(value)) {
      // If the input exceeds 10 characters, trim it
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setNewPrice(value);
    } 
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    // Validate if the entered value is a positive number
    if (/^\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const handleCategoryChange = (e) => {
    setProductCategory(e.target.value);
    setProductSubCategory(""); // Reset subcategory when category changes
  };
  return (
    <>
      <AdminNavbar adminData={adminData} />
      <section className="h-100 gradient-custom-2 section mt-4 pt-5">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-9 col-xl-7">
              <div className="card" style={{ padding: "7px" }}>
                <h1 className="heading">Add Product</h1>

                <Form className="container-lg  product_form">
                  <div className="product-form">
                    <Form.Group
                      controlId="formProductTitle"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Title*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Add Product Title"
                        name="productTitle"
                        value={productTitle}
                        onChange={(e) => setProductTitle(e.target.value)}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductContent"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Description*</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        placeholder="Write Your Product Description..."
                        name="productContent"
                        value={productContent}
                        onChange={(e) => setProductContent(e.target.value)}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="product-form"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Group
                      controlId="productMrpPrice"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>MRP Price*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="150"
                        name="mrpPrice"
                        value={mrpPrice}
                        onChange={handleMrpPriceChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="productNewPrice"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>New Price*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="100"
                        name="newPrice"
                        value={newPrice}
                        onChange={handleNewPriceChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductContent"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Quantity*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="100"
                        name="quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        style={{ border: " 1px solid #2e6ca4" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="product-form">
                    <Form.Group
                      controlId="formProductCategory"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Category*</Form.Label>
                      <Form.Select
                        onChange={(e) => setProductCategory(e.target.value)}
                        value={productCategory}
                        style={{ border: " 1px solid #2e6ca4" }}
                      >
                        <option value="">Select Category</option>
                        <option value="fish">Fish</option>
                        <option value="birds">Birds</option>
                        <option value="cat">Cat</option>
                        <option value="dog">Dog</option>
                        <option value="hamster">Hamster</option>
                        <option value="pigeon">Pigeon</option>
                        <option value="rabbit">Rabbit</option>
                        <option value="poultry">Poultry</option>
                      </Form.Select>
                    </Form.Group>
                  </div>

                  {productCategory === "fish" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="fish food">Fish Food</option>
                          <option value="fish tank">Fish Tank</option>
                          <option value="fish medicine">Fish Medicine</option>
                          <option value="fish tank accessories">
                            Fish Tank Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "birds" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubcategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="birds food">Birds Food</option>
                          <option value="birds cage">Birds Cage</option>
                          <option value="birds medicine">Birds Medicine</option>
                          <option value="birds cage accessories">
                            Birds Cage Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "cat" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="cat food">Cat Food</option>
                          <option value="cat toys">Cat Toys</option>
                          <option value="cat medicine">Cat Medicine</option>
                          <option value="cat accessories">
                            Cat Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "dog" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="dog food">Dog Food</option>
                          <option value="dog toys">Dog Toys</option>
                          <option value="dog medicine">Dog Medicine</option>
                          <option value="dog accessories">
                            Dog Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "hamster" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="hamster food">Hamster Food</option>
                          <option value="hamster cage">Hamster Cage</option>
                          <option value="hamster medicine">
                            Hamster Medicine
                          </option>
                          <option value="hamster accessories">
                            Hamster Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "pigeon" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="pigeon food">Pigeon Food</option>
                          <option value="pigeon medicine">
                            Pigeon Medicine
                          </option>
                          {/* <option value="pigeon cage">Pigeon Cage</option> */}
                          <option value="pigeon accessories">
                            Pigeon Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "rabbit" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubCategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="rabbit food">Rabbit Food</option>
                          <option value="rabbit cage">Rabbit Cage</option>
                          <option value="rabbit medicine">
                            Rabbit Medicine
                          </option>
                          <option value="rabbit accessories">
                            Rabbit Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  {productCategory === "poultry" && (
                    <div className="product-form">
                      <Form.Group
                        controlId="formProductSubcategory"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Label>Product Subcategory*</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setProductSubCategory(e.target.value)
                          }
                          value={productSubCategory}
                          style={{ border: " 1px solid #2e6ca4" }}
                        >
                          <option value="">Select Subcategory</option>
                          <option value="poultry food">Poultry Food</option>
                          <option value="poultry medicine">
                            Poultry Medicine
                          </option>
                          <option value="poultry accessories">
                            Poultry Accessories
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}

                  <div className="product-form">
                    <Form.Group
                      controlId="formImageUpload"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label>Product Image*</Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ border: "1px solid #2e6ca4" }}
                          // style={{ border: isUploading ? "1px solid #2e6ca4" : (imageUploaded ? "1px solid green" : "1px solid red") }}
                        />
                        <InputGroup.Text
                          className="icon"
                          style={{
                            cursor: "pointer",
                            border: "1px solid #2e6ca4",
                          }}
                            onClick={!isUploading ? uploadImage : null}
                          disabled={isUploading}
                        >
                          {isUploading ? (
                            <AiOutlineLoading3Quarters className="rotating-icon" />
                          ) : (
                            <MdBrowserUpdated
                              style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={!isUploading ? uploadImage : null}
                            />
                          )}
                          {isUploading ? "Uploading..." : "Upload"}
                        </InputGroup.Text>
                      </InputGroup>
                      {/* Additional UI for showing success message */}
                      {imageUploaded && (
                        <div className="alert-container" style={{display:"flex", flexDirection:"row"}}>
                          <p className="alert-msg" style={{ color: "green", padding:"0", margin:"0" }}>
                            Image Uploaded Successful
                          </p>
                          <FaSmile
                            style={{ color: "green", marginLeft: "5px" }}
                          />
                          <img
                          src={imageUrl}
                          alt="Uploaded Profile"
                          className="uploaded-image"
                          style={{width:"30px"}}
                        />
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <Button
                    variant="primary"
                    className="button"
                    style={{ margin: "20px 0px 20px 0px" }}
                    type="submit"
                    onClick={!isLoading ? handlePublish : null}
                    disabled={isLoading || !productTitle || !productContent || !mrpPrice || !newPrice || !quantity || !productCategory || !productSubCategory || !imageUploaded}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Publishing...
                      </>
                    ) : (
                      "Publish"
                    )}
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <Link
                    style={{ fontSize: "15px", textDecoration: "underline" }}
                    to={`/dashboard/${adminData._id}`}
                  >
                    <RiArrowGoBackFill style={{ fontSize: "20px" }} /> Back to
                    Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddProducts;
