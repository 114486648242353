import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import API from "../../api/API";
import {
  Spinner,
  Button,
  Tooltip,
  OverlayTrigger,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { RiArrowGoBackFill } from "react-icons/ri";
import Timeline from "./Timeline1";

const OrderData = () => {
  const [adminData, setAdminData] = useState({});
  const [userData, setUserData] = useState({});
  const [allOrders, setAllOrders] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);
  const [orderItemSummary, setOrderItemSummary] = useState([]);
  const [productData, setProductData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderStatuses, setOrderStatuses] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token || localStorage.getItem("token");

  const getAdminData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getadmin`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data.user);
    } catch (error) {
      console.log("Error fetching admin data:", error);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      getAdminData();
    }
  }, [token, navigate]);

  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/admin/getalluser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const users = {};
      response.data.users.forEach((user) => {
        users[user._id] = user;
      });
      setUserData(users);
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const getAllOrders = async () => {
    try {
      const response = await axios.get(`${API}/admin/getallorders`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedOrders = response.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const paidOrders = sortedOrders.filter(
        (order) => order.status === "Paid"
      );
      setAllOrders(paidOrders);

      const initialOrderStatuses = paidOrders.reduce((acc, order) => {
        acc[order.orderID] = {
          orderPicked: order.orderStatus === "orderPicked",
          orderPacked: order.orderStatus === "orderPacked",
          orderDespatched: order.orderStatus === "orderDespatched",
          bookingSuccessful: order.orderStatus === "bookingSuccessful",
        };
        return acc;
      }, {});
      setOrderStatuses(initialOrderStatuses);
    } catch (error) {
      console.log("Error fetching all orders:", error);
    }
  };

  const getOrderSummary = async () => {
    try {
      const response = await axios.get(`${API}/admin/orders`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const paidOrders = response.data.orders.filter(
        (order) => order.status === "Paid"
      );
      setOrderSummary(paidOrders);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };

  const getOrderItemsSummary = async () => {
    try {
      const response = await axios.get(`${API}/admin/orderItems`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrderItemSummary(response.data.orderItems);
    } catch (error) {
      console.error("Error fetching order summary:", error);
    }
  };

  const getProductData = async () => {
    try {
      const response = await axios.get(`${API}/product/showproducts`);
      const products = {};
      response.data.products.forEach((product) => {
        products[product._id] = product;
      });
      setProductData(products);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  const getPaymentData = async () => {
    try {
      const response = await axios.get(`${API}/admin/allpayment`);
      setPaymentData(response.data.payments);
    } catch (error) {
      console.log("Error fetching payment data:", error);
    }
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      Promise.all([
        getUserData(),
        getAllOrders(),
        getOrderSummary(),
        getOrderItemsSummary(),
        getProductData(),
        getPaymentData(),
      ]).then(() => setLoading(false));
    }
  }, [token]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOrders = searchTerm
    ? allOrders.filter((order) => order.orderID.includes(searchTerm))
    : allOrders;

  const handleStatusChange = async (statusKey, orderID) => {
    const updatedStatus = {
      ...orderStatuses[orderID],
      [statusKey]: true,
    };

    // Update the order status locally
    setOrderStatuses((prevStatuses) => ({
      ...prevStatuses,
      [orderID]: {
        ...prevStatuses[orderID],
        [statusKey]: new Date(), // Set the current date/time
      },
    }));

    try {
      // Make a PUT request to update the order status
      const response = await axios.put(
        `${API}/admin/update-order-status/${orderID}`,
        { orderStatus: statusKey }
      );
      console.log(
        "Order status updated:",
        response.data.updatedOrder.orderStatus
      );
    } catch (error) {
      console.error("There was an error updating the order status:", error);
    }
  };

  const handleBookingDataUpdate = (bookingData) => {
    console.log("Booking data received:", bookingData);
    // Update the state or perform any action needed with the booking data
  };

  return (
    <>
      <AdminNavbar adminData={adminData} />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="order-container">
          <h1 className="text-center mt-4">Orders</h1>
          <div
            className="container d-flex justify-content-between mb-3"
            style={{ gap: "1.2rem" }}
          >
            <InputGroup className="search-bar">
              <FormControl
                placeholder="Search order ID"
                aria-label="Search order ID"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  borderRadius: "30px",
                  padding: "10px 20px",
                  border: "1px solid #ced4da",
                }}
              />
            </InputGroup>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="order-summary">
              {filteredOrders.length > 0 ? (
                filteredOrders.map((order, index) => {
                  const user = userData[order.userID];
                  const payment = paymentData[order._id];
                  const currentOrderStatus = orderStatuses[order.orderID] || {
                    orderPicked: false,
                    orderPacked: false,
                    orderDespatched: false,
                    bookingSuccessful: false,
                  };
                  return (
                    <div key={index} style={{ marginBottom: "70px" }}>
                      <p>
                        Order #
                        <span style={{ fontWeight: "bold" }}>
                          {order.orderID}
                        </span>{" "}
                        was placed on{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(order.createdAt).toLocaleString()}{" "}
                        </span>
                        and is currently Payment Status{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {order.status}
                        </span>
                        .
                      </p>
                      <div className="order-item">
                        <div
                          className="order-header"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyItems: "flex-start",
                          }}
                        >
                          <h6>Name: {order.userName}</h6>
                          <h6>Mobile: {order.phoneNumber}</h6>
                          <h6>
                            Email:{" "}
                            {order.address.email ? order.address.email : ""}
                          </h6>
                        </div>
                        <div className="order-header">
                          <h6>Order ID: {order.orderID}</h6>
                          {order.paymentId && (
                            <h6>Payment ID: {order.paymentId.split("_")[1]}</h6>
                          )}
                        </div>
                        <div className="order-items">
                          <h5>Order Items:</h5>
                          {order.orderItems.map((orderItem, i) => {
                            const orderID = orderItemSummary.find(
                              (item) => item?._id === orderItem
                            );
                            const product = orderID?.productId
                              ? productData[orderID.productId]
                              : null;

                            if (!product) {
                              return <p key={i}>Product not found</p>;
                            }

                            return (
                              <div key={i} className="order-product">
                                <div className="product-info rounded-top d-flex flex-row justify-content-around">
                                  <div className="d-flex flex-column col-3">
                                    <img
                                      src={product.productImage}
                                      alt={product.productTitle}
                                      className="product-image"
                                    />
                                  </div>
                                  <div
                                    className="d-flex flex-column col-7 p-2"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <h5 style={{ fontSize: "18px" }}>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id={`tooltip-title-${product._id}`}
                                          >
                                            {product.productTitle}
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {product.productTitle.length > 25
                                            ? product.productTitle.slice(
                                                0,
                                                25
                                              ) + "..."
                                            : product.productTitle}
                                        </span>
                                      </OverlayTrigger>
                                    </h5>
                                    <p
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id={`tooltip-${product._id}`}
                                          >
                                            {product.productContent}
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {product.productContent.length > 25
                                            ? product.productContent.slice(
                                                0,
                                                25
                                              ) + "..."
                                            : product.productContent}
                                        </span>
                                      </OverlayTrigger>
                                    </p>
                                  </div>
                                  <div className="d-flex flex-column col-2">
                                    <h5>&#8377;{product.newPrice}</h5>
                                    <p>
                                      Qty:{" "}
                                      {
                                        orderItemSummary.find(
                                          (item) => item._id === orderItem
                                        )?.quantity
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className="order-product d-flex justify-content-between"
                          style={{ padding: 0, fontSize: "small" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <p style={{ margin: 0 }}>Total Price:</p>
                            <p style={{ margin: 0 }}>Delivery Charges:</p>
                            <h6 style={{ margin: 0 }}>Grand Total:</h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <p style={{ margin: 0 }}>
                              &#8377;{order.totalPrice}
                            </p>
                            <p style={{ margin: 0 }}>
                              &#8377;{order.deliveryCharge}
                            </p>
                            <h6 style={{ margin: 0 }}>
                              &#8377;{order.grandTotal}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div
                        className="order-address"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          padding: "20px",
                          gap: "10px",
                        }}
                      >
                        <h5>Address: </h5>{" "}
                        <p style={{ fontWeight: "bold" }}>
                          {order.address.name}
                        </p>{" "}
                        <p>
                          {order.address.address}, {order.address.city},{" "}
                          {order.address.state}, {order.address.zip}
                        </p>
                      </div>
                      <Timeline
                        orderStatus={currentOrderStatus}
                        onStatusChange={(statusKey) =>
                          handleStatusChange(statusKey, order.orderID)
                        }
                        order={order}
                        onBookingDataUpdate={handleBookingDataUpdate}
                      />
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div className="col-12">
                  <p>No orders found.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderData;
