import React, { useState, useEffect } from "react";
import NavbarComponent from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import API from "../../api/API";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dropdown,
  FormControl,
  InputGroup,
  Spinner,
  Button,
} from "react-bootstrap";
import { MdOutlineSort } from "react-icons/md";
import ProductCard from "../card/Card";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Pigeon = () => {
  const [cart, setCart] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});

  const token = location.state?.token || localStorage.getItem("token");

  // Fetch user data
  const getUserData = async () => {
    try {
      const response = await axios.get(`${API}/users/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch product data
  const getProductData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${API}/product/showproducts`);
      setProductData(response.data.products);
      filterProducts("pigeon", "");
      setLoader(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoader(false);
    }
  };

  // Load cart data from local storage when the component mounts
  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
    getUserData();
    getProductData();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const search = query.get("search") || "";
    filterProducts("pigeon", search);
  }, [location.search, productData]);

  // Filter products based on category and search term
  const filterProducts = (category, search) => {
    let filteredProducts = productData;

    if (category) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productCategory === category
      );
    }

    if (search) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.productTitle.toLowerCase().includes(search.toLowerCase()) ||
          product.productContent.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredProductData(filteredProducts);
  };

  // Group products by category and subcategory
  const groupProductsByCategory = (products) => {
    return products.reduce((acc, product) => {
      const category = product.productCategory || "Uncategorized";
      const subCategory = product.productSubCategory || "General";
      if (!acc[category]) {
        acc[category] = {};
      }
      if (!acc[category][subCategory]) {
        acc[category][subCategory] = [];
      }
      acc[category][subCategory].push(product);
      return acc;
    }, {});
  };

  // Add product to cart
  const handleAddToCart = (productData) => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const existingCart = JSON.parse(savedCart);
      const updatedCart = [...existingCart, productData];
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      setCart([productData]);
      localStorage.setItem("cart", JSON.stringify([productData]));
    }
  };

  // Handle category change
  const handleCategoryChange = (category) => {
    if (category) {
      navigate(`/${category}`);
    } else {
      navigate("/explore");
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterProducts("pigeon", event.target.value);
  };

  // Clear search input
  const handleClearSearch = () => {
    setSearchTerm("");
    filterProducts("pigeon", "");
  };

  // Grouped products by category
  const groupedProducts = groupProductsByCategory(filteredProductData);

  // Calculate total quantity in cart
  const totalQuantityInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Capitalize words
  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Helmet>
        <title>Pigeon Products & Accessories at Salluuu Pets</title>
        <meta
          name="description"
          content="Discover premium pigeon products and accessories at Salluuu Pets. Shop pigeon food, lofts, perches, and health supplies. Enjoy fast shipping and excellent customer service."
        />
      </Helmet>
      <NavbarComponent
        handleClearSearch={handleClearSearch}
        totalQuantityInCart={totalQuantityInCart}
        token={token}
        userName={userData.userName}
      />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <h1 className="text-center mt-4">All Pigeon Products</h1>
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          style={{ gap: "1.2rem" }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              style={{
                margin: "0",
                padding: "7px 15px",
                textTransform: "lowercase",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                borderRadius: "30px",
                backgroundColor: "#2e6ca4",
                border: "none",
                color: "#fff",
              }}
            >
              <MdOutlineSort style={{ fontSize: "20px" }} />
              Sort
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleCategoryChange("")}>
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("fish")}>
                Fish
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("birds")}>
                Birds
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("cat")}>
                Cat
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("dog")}>
                Dog
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("hamster")}>
                Hamster
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("pigeon")}>
                Pigeon
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("rabbit")}>
                Rabbit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleCategoryChange("poultry")}>
                Poultry
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <InputGroup className="search-bar">
            <FormControl
              placeholder="Search products"
              aria-label="Search products"
              aria-describedby="basic-addon2"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                borderRadius: "30px",
                padding: "10px 20px",
                border: "1px solid #ced4da",
              }}
            />
            {searchTerm && (
              <Button
                variant="outline-secondary"
                onClick={handleClearSearch}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  fontSize: "20px",
                  zIndex: 2,
                }}
              >
                &times;
              </Button>
            )}
          </InputGroup>
        </div>
      </div>

      <div className="container-fluid">
        {loader ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Skeleton height={40} width={"95vw"} style={{ margin: "10px" }} />

              <Skeleton height={30} width={"90vw"} style={{ margin: "10px" }} />
            </div>
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {Array(8)
                .fill()
                .map((_, index) => (
                  <div
                    style={{
                      margin: "10px",
                      width: "250px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <Skeleton height={180} width={250} />
                    <div style={{ padding: "10px" }}>
                      <Skeleton
                        height={20}
                        width="60%"
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton
                        height={20}
                        width="80%"
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton
                        height={20}
                        width="40%"
                        style={{ marginBottom: "20px" }}
                      />
                      <Skeleton
                        height={20}
                        width="100%"
                        style={{ marginBottom: "10px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Skeleton
                          circle={true}
                          height={40}
                          width={40}
                          style={{ margin: "0 10px" }}
                        />
                        <Skeleton
                          height={40}
                          width={40}
                          style={{ margin: "0 10px" }}
                        />
                        <Skeleton
                          circle={true}
                          height={40}
                          width={40}
                          style={{ margin: "0 10px" }}
                        />
                      </div>
                      <Skeleton height={40} width="100%" />
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : filteredProductData.length === 0 ? (
          <div className="text-center mt-4">
            <h3>No Products Found</h3>
          </div>
        ) : (
          Object.keys(groupedProducts)
            .sort((a, b) => {
              const categoryOrder = {
                fish: 1,
                birds: 2,
                cat: 3,
                dog: 4,
                hamster: 5,
                pigeon: 6,
                rabbit: 7,
                poultry: 8,
              };
              return (categoryOrder[a] || 100) - (categoryOrder[b] || 100);
            })
            .map((category) => (
              <div
                key={category}
                style={{
                  boxShadow:
                    "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                }}
              >
                <h2
                  className="text-center my-4"
                  style={{
                    fontSize: "24px",
                    color: "#fff",
                    backgroundColor: "#007bff",
                    padding: "10px",
                    marginBottom: "20px",
                  }}
                >
                  {capitalizeWords(category)} Products
                </h2>
                {Object.keys(groupedProducts[category]).map((subCategory) => (
                  <div
                    key={subCategory}
                    style={{
                      margin: "1.2rem",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <h5
                      className="text-center my-3"
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        backgroundColor: "#028391",
                        padding: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {capitalizeWords(subCategory)}
                    </h5>
                    <div
                      className="d-flex flex-wrap justify-content-center"
                      style={{ gap: "1.2rem" }}
                    >
                      {groupedProducts[category][subCategory].map((product) => (
                        <div key={product._id}>
                          <ProductCard
                            product={product}
                            onAddToCart={handleAddToCart}
                            setCart={setCart}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <hr />
              </div>
            ))
        )}
      </div>

      <Footer />
    </>
  );
};

export default Pigeon;
